import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { useFormContext, UseFormMethods, ValidationRules } from 'react-hook-form';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Modal from 'react-responsive-modal';
import { Api, Endpoint } from '../../../services/api.service';
import { IFileType } from '../../../entities/file-type.entity';
const pdf = require(`../../../img/pdf.png`);

export interface UploadPreviewUrl {
  thumb: string;
  full: string;
};

interface Props {
  fileType: IFileType;
  urls?: UploadPreviewUrl[];
  className?: any;
  autoFocus?: boolean;
  tabIndex?: number;
  picShow?: boolean;
  FormEditingOnclick?: (e: any) => void
  FormAddingOnclick?: (e: any) => void;
  cropMode?: boolean
  aspectRatio?: 'square'
  disableDelete?: boolean
}

interface DropzoneFile extends File {
  dataUrl?: string;
}

export let show: boolean = false;
export let selectedPic: string | undefined = '';
export let selectedUrl: string | undefined = '';

export function FormUpload(props: Props & UseFormMethods) {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  let expandedImgRef!: HTMLImageElement | null;
  let dropzone = useRef<DropzoneRef>(null);
  const context = useFormContext()
  const { clearErrors } = context
  const {
    fileType, urls, className, register, errors, FormEditingOnclick, FormAddingOnclick,
    setError, setValue, tabIndex, cropMode, aspectRatio, disableDelete } = props;
  const { id, name, description, maxCount, required } = fileType;
  const multiple = maxCount > 1;
  const [files, setFiles] = useState<DropzoneFile[] | undefined>([]);
  const [fileName, setFileName] = useState();
  const [urlsData, setUrlsData] = useState(urls);
  const [cropStatus, setCropStatus] = useState(false)
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<any>(undefined);
  const [editMode, setEditMode] = useState<boolean>();
  const [expanded, setExpanded] = useState<string>();
  const [storagePath, setStoragePath] = useState<string>();
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => { setOpen(false)};

  useEffect(() => {
    if (files && files.length > 0) {
      if (multiple) {
        selectedUrl = storagePath;
      }
      else {
        setUrlsData(() => {
          return urls?.filter((url: any) =>
            url.thumb === files[0].dataUrl
          )
        })
      }
    }
    else {
      setUrlsData(urls);
    }
  }, [urls, files]);
  useEffect(() => register({ name: id }), [register, id]);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);

  useEffect(() => {
    setEditMode(true);
  }, []);

  const getImage = useCallback(() => {

    return <img key={image} crossOrigin='anonymous' height={700} id="cropImg" src={image ? image : files && files[0]?.dataUrl} />
  }, [files, image])

  const onDropped = (_files: File[], fileRejections: any) => {
    // setEditMode(false)
    let __files: DropzoneFile[] | undefined | any
    if (multiple) {
      __files = files?.concat(_files);
    }
    else {
      __files = _files;
    }
    if (__files) {
      for (let __file of __files) {
        __file.dataUrl = __file.dataUrl || URL.createObjectURL(__file);
      }
      setFiles(__files);
      show = false;
      setValue(id, __files[0])
      if (cropMode) {
        setImage(undefined);
        setOpen(true);
      }
    }
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (files) {
      files.forEach(file => URL.revokeObjectURL((file as any).preview));
      setValue(id, files);
    }
  }, [files]);

  const onError = (rejections: FileRejection[]) => {
    const [{ errors }] = rejections;
    const [error] = errors;
    setError(id, { type: error.code, message: error.message });
  }

  const onClick = (url: string) => {
    if (multiple) {
      setExpanded(url);
    } else {
      dropzone.current?.open();
    }
  }

  const onPdfClick = (url: string) => {
    window.open(url);
  }

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const hideDropzone = () => !multiple && files && files.length > 0;
  const hasPreview = () => files && files.length > 0 || (urlsData && urlsData.length > 0);

  async function getCroppedImg() {
    var img: any = document.getElementById("cropImg");
    const canvas = document.createElement('canvas');
    if (crop) {
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx: any = canvas.getContext('2d');
      const scaleX = img.naturalWidth / img.width;
      const scaleY = img.naturalHeight / img.height;
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(
        img,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      canvas.toBlob(function async(blob) {        // get content as JPEG blob
        // here the image is a blob
        if (blob) {
          const url = URL.createObjectURL(blob)
          let file: any = new File([blob], id, { type: "image/png" })
          let fileData: any = files
          if (multiple) {
            // if multiple need to set selectedUrl to remove crop's original image
            selectedUrl = storagePath
            // clearing the original image
            setUrlsData(() => {
              return urlsData?.filter((url: any) =>
                url.thumb !== storagePath
              )
            });
            // callback to the parent to send remove image request to the backend
            if (FormEditingOnclick) {
              file.dataUrl = url
              fileData.push(file)
              setFiles(() => {
                return files?.filter((file: any) =>
                  file.dataUrl !== storagePath
                )
              })

              FormEditingOnclick({})
            }
          }
          else {
            fileData[0] = file
            fileData[0].dataUrl = url
            setFiles(fileData)
            console.log("File Data",fileData)
          }
          // const data = urlsData?.filter(item=> item.thumb !== storagePath)
          // setUrlsData(data)
          setValue(id, fileData);
          setOpen(!open)
        }
      }, "image/png", 0.75);
    }
  }


  const handleDelete = async () => {
    const responseDelete = await Api.post<any, any>(Endpoint.DELETE_UPLOADED_FILE, {
      fileName
    });
  }
  const handleEdit = async (url: string) => {
    try {
      setStoragePath(url)
      let extension = url;

      if (extension.match(/\.(jpg|jpeg|png|gif|bin)$/i)) {
        const response = await Api.get<any, any>(Endpoint.UPLOADED_FILE, {
          src: url
        });
        const backendURL: any = process.env?.REACT_APP_API_URL
        let filePath = backendURL.split('v1');
        filePath = filePath[0] + response.path

        setOpen(!open)
        setTimeout(() => {
          setImage(filePath)
        }, 500);
        setFileName(response.path)
      }
      else {
        setOpen(!open)
        setTimeout(() => {
          setImage(url)
        }, 500);
      }
    }
    catch (error) {
      throw error
    }
  }
  return (
    <>
      <Modal  closeOnOverlayClick={false} open={open} onClose={onCloseModal} center>
      <ReactCrop style={{backgroundColor:'white'}} aspect={aspectRatio === 'square'?1:undefined} crop={crop} onChange={data => setCrop(data)}>
          {files && getImage()}
        </ReactCrop>
        <br></br>
        <button disabled={crop?.width == 0 ? true : false} onClick={getCroppedImg} className='btn btn-primary'>Crop</button>
      </Modal>
      <div className={classNames(
        {
          'form-upload': true,
          multiple: multiple,
          single: !multiple,
          'has-error': errors[id],
        },
        className,
      )} tabIndex={tabIndex}>
        {expanded && (
          <div>
            <img
              ref={r => expandedImgRef = r}
              className="expanded-img"
              src={expanded}
              alt={name}
            />
            <div className="expanded-backdrop"></div>
          </div>)}
        {hasPreview() && (<div className="preview-container">
          {urlsData?.map((url, i) => (
            url?.thumb.includes('admin_single.pdf') ?
              <div key={i}>
                <img key={i} src={pdf} style={{ cursor: 'pointer' }}
                  alt={i.toString()} onClick={() => onPdfClick(url?.full)}
                />
                {!disableDelete ? <a className="b" href="#delete" style={{ zIndex: 1000000, position: "absolute" }} onClick={(e: any) => {
                  e.preventDefault()
                  selectedUrl = url?.thumb
                  if (FormEditingOnclick) {
                    FormEditingOnclick(e)
                  }
                }}>
                  <i className="os-icon os-icon-trash-2"></i>
                </a> : ""}
              </div> :
              <div style={{ position: "relative" }} key={i}>
                <img
                  style={{ objectFit: "cover" }}
                  width="45%"
                  src={url?.thumb}
                  className="preview"
                  alt={name}
                  onClick={() => onClick(url?.full)}
                />
                {!open ?
                  < >
                    {!disableDelete ? <a className="b" href="#delete" style={{ zIndex: 0, position: "absolute", top: "25px" }} onClick={(e: any) => {
                      e.preventDefault()

                      // selectedUrl is used in parent component to remove the image
                      selectedUrl = url?.thumb

                      // url preview array remove that specific photo which is removed
                      setUrlsData(() => {
                        const data = urlsData.filter((urlItem: any) => urlItem.thumb != url.thumb);
                        return data
                      });

                      // callback to parent component by sending id in selectedUrl above
                      if (FormEditingOnclick) {
                        FormEditingOnclick(e)
                      }
                    }}>
                      <i className="os-icon os-icon-trash-2"></i>
                    </a> : ""}
                    {editMode ? <a key={i} className="b" href="#edit" style={{ zIndex: 0, position: "absolute" }} onClick={(e) => { e.preventDefault(); handleEdit(url.thumb) }}>
                      <i className="os-icon os-icon-edit"></i>
                    </a> : ""}
                  </>
                  : ""}
              </div>
          ))}
          {files && files.map((file, i) => (
            file.type.includes('application/pdf') ?
              <div key={i}>
                <img src={pdf} style={{ cursor: 'pointer' }}
                  alt={i.toString()} onClick={() => onPdfClick(file.dataUrl as string)}
                />
                {!disableDelete ? <a key={i} className="b" href="#delete" style={{ zIndex: 0, position: "absolute" }} onClick={(e: any) => {
                  e.preventDefault()
                  clearErrors(id);
                  setFiles(() => {
                    return files.filter((fill: DropzoneFile) => fill.dataUrl != file.dataUrl);
                  });
                  setValue('')
                  selectedPic = file.dataUrl
                  show = true
                }}>
                  <i className="os-icon os-icon-trash-2"></i>
                </a> : ""}
              </div> :
              <div style={{ position: "relative" }} key={i}>
                <img
                  style={{ objectFit: "cover",minWidth: "50px"  }}
                  width="45%"
                  src={file.dataUrl}
                  className="preview"
                  alt={name}
                  // setting a min width to the image
                  onClick={() => onClick(file.dataUrl as string)}
                />
                {!open ?
                  <>
                    {!disableDelete ? <a className="b" href="#delete" style={{ zIndex: 0, position: "absolute", top: "25px" }} onClick={(e: any) => {
                      e.preventDefault()
                      clearErrors(id);
                      setFiles(() => {
                        const data = files.filter((fill: DropzoneFile) => fill.dataUrl != file.dataUrl);
                        return data
                      });
                      setValue("");
                      selectedPic = file.dataUrl
                      show = true
                    }}>
                      <i className="os-icon os-icon-trash-2"></i>
                    </a> : ""}

                    {editMode ? <a key={i} className="b" href="#edit" style={{ zIndex: 0, position: "absolute" }} onClick={(e) => { e.preventDefault(); handleEdit(file.dataUrl as string) }}>
                      <i className="os-icon os-icon-edit"></i>
                    </a> : ""}
                  </>
                  : ""}
              </div>
          ))}
        </div>
        )}
        <Dropzone
          ref={dropzone}
          accept="image/jpeg,image/png,image/jpg, application/pdf"
          multiple={multiple}
          maxSize={5 * 1024 * 1024}
          onDropRejected={onError}
          onDrop={onDropped}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({
              className: classNames(
                'upload-dropzone',
                { 'hidden': hideDropzone() },
                { 'has-preview': hasPreview() },
              )
            })}>
              <input ref={register} name={id} {...getInputProps()} tabIndex={tabIndex} />
              <h3>{name}<label style={{ color: 'red' }}>{required ? '*' : ''}</label></h3>

              {description && <p>{description}</p>}
              {errors[id] && <p className="error-message">{errors[id].message}</p>}
            </div>
          )}
        </Dropzone>

        {multiple ? urlsData?.map((_, i) => (
          <>
            <input ref={register} type="hidden" name={`${id}[${i}].id`} />
            <input ref={register} type="hidden" name={`${id}[${i}].extension`} />
            <input ref={register} type="hidden" name={`${id}[${i}].type`} />

          </>
        )) : (
          <>
            <input ref={register} type="hidden" name={`${id}.id`} />
            <input ref={register} type="hidden" name={`${id}.extension`} />
            <input ref={register} type="hidden" name={`${id}.type`} />
          </>
        )}
      </div>
    </>
  );
}