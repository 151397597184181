import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatFileSize, lightenDarkenColor, useCSVReader } from 'react-papaparse';
import { Modal } from 'react-responsive-modal';
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import FloatingButton from '../../../components/emergency/floating.button';
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Activity, Driver, DriverStatus, Gender } from "../../../entities/driver.entity";
import { EmergencyStatus } from "../../../entities/emergency.entity";
import { Role, RoleAssignment } from "../../../entities/role.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import FemaleImg from "../../../img/female.png";
import MaleImg from "../../../img/male.png";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";
import { getStorageUrl } from "../../../util/file.util";
import { DriverPost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";
import { IFileType } from "../../../entities/file-type.entity";
export enum ProfilePicSize {
  List = "admin_large",
  Single = "admin_single",
}

const groupFilters: GroupFilter[] = [
  { title: "active", icon: "user-check" },
  { title: "all", icon: "phone-21" },
  { title: "new", icon: "alert-circle" },
  { title: "pending-approval", icon: "edit-1" },
  { title: "pending-data", icon: "newspaper" },
  { title: "payments", icon: "coins-4" },
  { title: "failed", icon: "cancel-circle" },
  { title: "blocked", icon: "user-x" },
  { title: "deleted", icon: "deleted" },
];

const statusFilters: StatusFilter[] = [
  { title: "online", status: ListItemStatus.Green },
  { title: "on-trip", status: ListItemStatus.Blue },
  { title: "offline", status: ListItemStatus.Red },
];
const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: { alignItems: 'center', border: `2px dashed #047bf8`, borderRadius: 20, display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', padding: 20, } as CSSProperties,
  file: { background: 'linear-gradient(to bottom, #EEE, #DDD)', borderRadius: 20, display: 'flex', height: 120, width: 120, position: 'relative', zIndex: 10, flexDirection: 'column', justifyContent: 'center', } as CSSProperties,
  info: { alignItems: 'center', display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, } as CSSProperties,
  size: { backgroundColor: GREY_LIGHT, borderRadius: 3, marginBottom: '0.5em', justifyContent: 'center', display: 'flex', } as CSSProperties,
  name: { backgroundColor: GREY_LIGHT, borderRadius: 3, fontSize: 12, marginBottom: '0.5em', } as CSSProperties,
  progressBar: { bottom: 14, position: 'absolute', width: '100%', paddingLeft: 10, paddingRight: 10, } as CSSProperties,
  zoneHover: { borderColor: GREY_DIM, } as CSSProperties,
  default: { borderColor: GREY, } as CSSProperties,
  remove: { height: 23, position: 'absolute', right: 6, top: 6, width: 23, } as CSSProperties,
};

interface alert {
  className: string;
  message: string;
}
export function DriverList(props: any) {
  const { CSVReader } = useCSVReader();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const { t } = useTranslation("list");
  let history = useHistory();
  const { y: pageYOffset } = useWindowScroll();
  const [allCount, setAllCount] = useState<number>(0);
  const [newCount, setNewCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);
  const [pendingDataCount, setPendingDataCount] = useState<number>(0);
  const [paymentCount, setPaymentCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  const [changeStatus, setChangeStatus] = useState<DriverStatus | undefined>(undefined);
  const [monitorAccess, setMonitorAccess] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [pro, setPro] = useState<string>(props.location.state);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState(false);
  const [drivers, setDrivers] = useState<Driver[] | undefined>(undefined);
  const [selectedDriver, setSelectedDriver] = useState<Driver>();
  const [size, setSize] = useState<number>();
  const [zoneHover, setZoneHover] = useState(false);
  const [alertMessage, setAlertMessage] = useState<alert | null>(null)
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<StatusFilter | undefined>(undefined);
  const [fileType, setFileType] = useState<IFileType>();

  useEffect(() => {
    const Payload: any | undefined = AuthService.getUser();
    const { user } = Payload;
    const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
    const moduleCodes = Array.from(assignments, assignment => ({
      module: assignment.module,
      action: assignment.action
    }));
    const newCodes = moduleCodes.filter((item: any) => {
      return item.module == "driver" && item.action == "monitor"
    })
    if (newCodes.length > 0) setMonitorAccess(true)
    else setMonitorAccess(false)
    fetchFileType();
  }, []);


  useEffect(() => {
    setSelectedDriver(props.location.state);
  }, [props.location.state]);

  const refresh = useCallback(
    async (e?: any) => {
      let group: string | undefined = activeGroupFilter?.title;
      group = group === "all" ? undefined : group;
      let status = activeStatusFilter?.title;
      let isDeleted = false;
      if (group == "deleted") {
        group = undefined;
        isDeleted = true;
      }

      setLoadingList(true);

      const { items, totalItems } = await Api.get<IPaginationResponse<Driver>, IPaginationOptions>(Endpoint.DRIVER_LIST, { page, limit, status, group, search, isDeleted, });
      if (items && items.length > 0 && drivers && drivers.length > 0) {
        if (items[0].id !== drivers[0].id) {
          setSelectedDriver(items[0]);
        }
      }
      setDrivers(items);

      if (items && items.length !== totalItems) {
        setHasMore(true);
      } else {
        setHasMore(false)
      };

      if (page > 1) {
        setHasPrevious(true);
      } else {
        setHasPrevious(false);
      }

      const counts = await Api.get<{ totalCount: number, activeCount: number, newCount: number, pendingApprovalCount: number, pendingDataCount: number, blockedCount: number, deletedCount: number, paymentCount: number }, undefined>(Endpoint.DRIVER_COUNTS);

      setAllCount(counts.totalCount);
      setActiveCount(counts.activeCount);
      setNewCount(counts.newCount);
      setPendingApprovalCount(counts.pendingApprovalCount);
      setPendingDataCount(counts.pendingDataCount);
      setFailedCount(0);
      setBlockedCount(counts.blockedCount);
      setDeletedCount(counts.deletedCount);
      setPaymentCount(counts.paymentCount);

      if (selectedDriver?.status === DriverStatus.Blocked) {
        setChangeStatus(DriverStatus.Active);
      } else if (selectedDriver?.status === DriverStatus.Active) {
        setChangeStatus(DriverStatus.Blocked);
      } else {
        setChangeStatus(undefined);
      }

      if (items.length > 0 && !selectedDriver && !props.location.state) {
        setSelectedDriver(items[0]);
      }
      setLoadingList(false);
    },
    [search, activeGroupFilter, page, limit, activeStatusFilter, hasMore, hasPrevious]
  );

  useEffect(() => {
    refresh();
  }, [refresh, props.location.state]);

  const fetchMoreData = () => {
    if (hasMore)
      setPage(page + 1);
  };

  const fetchPreviousData = () => {
    if (hasPrevious)
      setPage(page - 1);
  }

  const deleteDriver = async () => {
    await Api.delete(Endpoint.DRIVER, { id: selectedDriver?.id });
    setSelectedDriver(undefined);
    refresh();
  };

  const getProfilePic = (driver: Driver, type: ProfilePicSize): string => {
    console.log("Getting Profile Pic", driver)
    if (driver?.picFile?.id && driver?.id) {
      return `${getStorageUrl()}/driver/${driver.id}/${fileType?.id}/${driver.picFile?.id
        }/${type}.${driver.picFile?.extension}`;
    } else {
      return driver.gender === Gender.Female ? FemaleImg : MaleImg;
    }
  };

  const getFullName = (driver: Driver): string => {
    return [driver.firstName, driver.lastName].join(" ");
  };

  const addDriver = () => {
    setFormMode(FormMode.Adding);
  };

  const importDrivers = (e: any) => {
    console.log('Inside Import Drivers:', e.target.files)
  }

  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const editDriver = () => {
    setFormMode(FormMode.Editing);
  };

  const cancelEditing = () => {
    setFormMode(undefined);
  };

  const onNext = () => {
    if (drivers) {
      const currentDriverIndex = drivers.findIndex(
        (driver) => !!selectedDriver && driver.id === selectedDriver.id
      );
      if (
        currentDriverIndex + 1 < drivers.length &&
        currentDriverIndex !== -1
      ) {
        setSelectedDriver(drivers[currentDriverIndex + 1]);
      }
    }
  };

  const onPrev = () => {
    if (drivers) {
      const currentDriverIndex = drivers.findIndex(
        (driver) => !!selectedDriver && driver.id === selectedDriver.id
      );
      if (currentDriverIndex - 1 > -1 && currentDriverIndex !== -1) {
        setSelectedDriver(drivers[currentDriverIndex - 1]);
      }
    }
  };

  const toListItems = (driver?: Driver): ListItem<Driver> | undefined => {
    if (!driver) {
      return;
    }
    let status;
    if (driver.activity == Activity.Online) {
      status = ListItemStatus.Green;
    }
    if (driver.activity == Activity.Offline) {
      status = ListItemStatus.Red;
    }
    if (driver.activity == Activity.OnTrip) {
      status = ListItemStatus.Blue;
    }

    return {
      id: driver.id as string,
      title: `${driver.code} - ${getFullName(driver)}`,
      imgSrc: getProfilePic(driver, ProfilePicSize.List),
      status: status ? status : ListItemStatus.Yellow,
      deleted: driver.deletedTime,
      ref: driver,
    };
  };
  const fetchFileType = async () => {
    const response = await Api.get<IFileType[], undefined>(Endpoint.DRIVER_FILE_TYPES);
    setFileType(response.find(item => item.name === 'Driver Pic'));
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedDriver(undefined);
    setPage(1);
    setActiveGroupFilter(target);
  };

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedDriver(undefined);
    setPage(1);
    setActiveStatusFilter(
      target?.title !== activeStatusFilter?.title ? target : undefined
    );
  };

  const onSearchChange = (search: string) => {
    setPage(1);
    setSearch(search);
  };

  const statusChange = async () => {
    if (selectedDriver?.status === DriverStatus.Active) {
      await Api.patch(Endpoint.DRIVER, {
        id: selectedDriver.id,
        status: (selectedDriver.status = DriverStatus.Blocked),
      });
      refresh();
    } else if (selectedDriver?.status === DriverStatus.Blocked) {
      await Api.patch(Endpoint.DRIVER, {
        id: selectedDriver.id,
        status: (selectedDriver.status = DriverStatus.Active),
      });
      refresh();
    }
  };

  const updateSize = () => {
    setSize(window.screen.width);
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <h4 style={{ marginTop: '20px', textAlign: 'center' }}>Import CSV file </h4>
        <CSVReader
          config={{
            header: true, skipEmptyLines: true
          }}
          onUploadAccepted={async (results: any) => {
            console.log('---------------------------');
            console.log(results);
            const response = await Api.post(Endpoint.DRIVER_IMPORT, { csv: (results.data) })
            console.log("response", response)
            if (response) {
              setAlertMessage({
                className: "success",
                message: "Uploaded SuccessFully !"
              })
            }
            else {

              setAlertMessage({
                className: "danger",
                message: "Somthing Went Wrong !"
              })

            }
            setZoneHover(false);
          }}
          onDragOver={(event: DragEvent) => {
            event.preventDefault();
            setAlertMessage(null)
            setZoneHover(true);
          }}
          onDragLeave={(event: DragEvent) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
            Remove,
          }: any) => (
            <>
              <div
                {...getRootProps()}
                style={Object.assign(
                  {},
                  styles.zone,
                  zoneHover && styles.zoneHover
                )}
              >
                {acceptedFile ? (
                  <>
                    {alertMessage ? <div className={`alert alert-${alertMessage.className}`} role="alert">
                      {alertMessage.message}
                    </div> : ""}
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>
                          {formatFileSize(acceptedFile.size)}
                        </span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        style={styles.remove}
                        onMouseOver={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onMouseOut={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  'Drop CSV file here or click to upload'
                )}
              </div>
            </>
          )}
        </CSVReader>
      </Modal>
      {size && size < 1000 ? (
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: `active`, icon: "user-check", count: activeCount },
                  { title: "all", icon: "phone-21", count: allCount },
                  // { title: "new", icon: "alert-circle", count: newCount },
                  {
                    title: "pending-approval",
                    icon: "edit-1",
                    count: pendingApprovalCount,
                  },
                  {
                    title: "pending-data",
                    icon: "newspaper",
                    count: pendingDataCount,
                  },
                  {
                    title: "payments",
                    icon: "coins-4",
                    count: paymentCount,
                  },
                  // {
                  //   title: "failed",
                  //   icon: "cancel-circle",
                  //   count: failedCount,
                  // },
                  { title: "blocked", icon: "user-x", count: blockedCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                statusFilters={statusFilters}
                activeGroupFilter={activeGroupFilter}
                activeStatusFilter={activeStatusFilter}
                onGroupSelect={selectGroup}
                onStatusSelect={selectStatus}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={drivers?.map(toListItems) as ListItem<Driver>[]}
                activeItem={toListItems(selectedDriver)}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                hasPrevious={hasPrevious}
                fetchMoreData={fetchMoreData}
                fetchPreviousData={fetchPreviousData}
                loading={loadingList}
                onSearchChange={onSearchChange}
                onRefresh={() => {
                  setDrivers(undefined);
                  refresh();
                }}
                onClick={(item: ListItem<Driver>) => {
                  setSelectedDriver(item.ref);
                }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                drivers !== undefined ? (
                  selectedDriver ? (
                    <View
                      componentName={"driver"}
                      changeDriverStatus={changeStatus}
                      showAdd={true}
                      showAudit={true}
                      showEdit={true}
                      showDelete={selectedDriver.deletedTime ? false : true}
                      showNext={true}
                      showImport={true}
                      showPrev={true}
                      showStateChange={true}
                      onImport={onOpenModal}
                      onAdd={addDriver}
                      onAudit={() => checkAudit(selectedDriver.id)}
                      onEdit={editDriver}
                      onDelete={deleteDriver}
                      onStateChange={statusChange}
                      onPrev={onPrev}
                      onNext={onNext}
                      childClassName={"p-0"}
                    >
                      <SelectedItemContent
                        key={selectedDriver.id}
                        selectedDriver={selectedDriver}
                        getFullName={getFullName}
                        getProfilePic={getProfilePic}
                        onApprove={refresh}
                        setSelectedDriver={setSelectedDriver}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button
                        onClick={addDriver}
                        className="btn btn-primary text-bold"
                      >
                        {t("driver.addDriver")}
                      </button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("driver.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : selectedDriver ? (
                <DriverPost
                  style={FormStyle.Containerized}
                  formMode={formMode}
                  cancel={cancelEditing}
                  entityId={selectedDriver.id}
                  onAfterSave={() => refresh(true)}
                />
              ) : (
                <DriverPost
                  style={FormStyle.Containerized}
                  formMode={formMode}
                  cancel={cancelEditing}
                  onAfterSave={() => refresh(true)}
                />
              )}
            </div>
          </div>
        </List>
      ) : (
        <List>
          <ListFilter
            groupFilters={[
              { title: `active`, icon: "user-check", count: activeCount },
              { title: "all", icon: "phone-21", count: allCount },
              // { title: "new", icon: "alert-circle", count: newCount },
              {
                title: "pending-approval",
                icon: "edit-1",
                count: pendingApprovalCount,
              },
              {
                title: "pending-data",
                icon: "newspaper",
                count: pendingDataCount,
              },
              {
                title: "payments",
                icon: "coins-4",
                count: paymentCount,
              },
              // { title: "failed", icon: "cancel-circle", count: failedCount },
              { title: "blocked", icon: "user-x", count: blockedCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            statusFilters={statusFilters}
            activeGroupFilter={activeGroupFilter}
            activeStatusFilter={activeStatusFilter}
            onGroupSelect={selectGroup}
            onStatusSelect={selectStatus}
          />
          <ListItems
            items={drivers?.map(toListItems) as ListItem<Driver>[]}
            activeItem={toListItems(selectedDriver)}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            hasPrevious={hasPrevious}
            fetchMoreData={fetchMoreData}
            fetchPreviousData={fetchPreviousData}
            loading={loadingList}
            onSearchChange={onSearchChange}
            onRefresh={() => {
              setDrivers(undefined);
              refresh();
            }}
            onClick={(item: ListItem<Driver>) => {
              setSelectedDriver(item.ref);
            }}
          />
          {formMode === undefined ? (
            drivers !== undefined ? (
              selectedDriver ? (
                <View
                  componentName={"driver"}
                  changeDriverStatus={changeStatus}
                  showAdd={true}
                  showAudit={true}
                  showEdit={true}
                  showDelete={selectedDriver.deletedTime ? false : true}
                  showNext={true}
                  showPrev={true}
                  showImport={true}
                  showStateChange={true}
                  onAdd={addDriver}
                  onImport={onOpenModal}
                  onAudit={() => checkAudit(selectedDriver.id)}
                  onEdit={editDriver}
                  onDelete={deleteDriver}
                  onStateChange={statusChange}
                  onPrev={onPrev}
                  onNext={onNext}
                  childClassName={"p-0"}
                >
                  <SelectedItemContent
                    key={selectedDriver.id}
                    selectedDriver={selectedDriver}
                    getFullName={getFullName}
                    getProfilePic={getProfilePic}
                    onApprove={refresh}
                    setSelectedDriver={setSelectedDriver}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button
                    onClick={addDriver}
                    className="btn btn-primary text-bold"
                  >
                    {t("driver.addDriver")}
                  </button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("driver.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : selectedDriver ? (
            <DriverPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedDriver.id}
              onAfterSave={() => refresh(true)}
            />
          ) : (
            <DriverPost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              onAfterSave={() => refresh(true)}
            />
          )}
        </List>
      )}
      {selectedDriver && selectedDriver.activity !== Activity.Offline && monitorAccess && (
        <FloatingButton marginBottom={10} type="ADMIN SIDE"
          // @ts-ignore
          request={{
            action: "medium",
            driver: selectedDriver,
            driverId: selectedDriver.id!,
            level: "medium",
            createTime: new Date().toISOString(),
            status: EmergencyStatus.TakenByWebAdmin,
            type: "DriverApp",
            peerId: "9f2becc2-48c7-4f22-bb92-934f4ecfc4ff",
          }} />
      )}
    </div>
  );
}