import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Vehicle } from "../../../entities/vehicle.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import vehicleImg from '../../../img/vehicle.png';
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { getStorageUrl } from "../../../util/file.util";
import { VehiclePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'all', icon: 'phone-21' },
  { title: 'pending', icon: 'ui-22' },
  { title: 'new', icon: 'documents-03' },
  { title: "active", icon: "user-check" },
  { title: "deleted", icon: "trash" },
];

// const statusFilters: StatusFilter[] = [
//   { title: 'Online', status: ListItemStatus.Green },
//   { title: 'Metering', status: ListItemStatus.Blue },
//   { title: 'Offline', status: ListItemStatus.Red },
// ];

export enum ProfilePicSize {
  List = 'admin_large',
  Single = 'admin_single',
}

export function VehicleList(props: any) {

  const { t } = useTranslation(['main', 'list']);
  const { y: pageYOffset } = useWindowScroll();

  const [allCount, setAllCount] = useState<number>(0);
  const [newCount, setNewCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [blockedCount, setBlockedCount] = useState<number>(0);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [vehicles, setVehicles] = useState<Vehicle[] | undefined>(undefined);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<StatusFilter | undefined>(undefined);
  const [size, setSize] = useState<number>();
  
  useEffect(() => {
    setSelectedVehicle(props.location.state)
  }, [props.location.state])
  const refresh =(async (e?: any) => {
    let group: string | undefined = activeGroupFilter?.title;

    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    let isDeleted = false;
    if (group == "deleted") {
      group = undefined;
      isDeleted = true;
    }
    const { items, totalItems } = await Api.get<IPaginationResponse<Vehicle>, IPaginationOptions>(Endpoint.VEHICLE_LIST, { page: 0, limit, group, status, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedVehicle(props.location.state);
        setVehicles(items);
      }
      else {
        setVehicles(items);
      }
    }

    const ITEMS = await Api.get<
      IPaginationResponse<Vehicle>,
      IPaginationOptions
    >(Endpoint.VEHICLE_LIST, { limit: 10000000 });


    const response = await Api.get<any, any>(Endpoint.VEHICLE_DELETED_COUNT);
    setDeletedCount(response);
    setAllCount(ITEMS.totalItems)
    setActiveCount(ITEMS.items.filter(item => item.status === 'active').length)
    setNewCount(ITEMS.items.filter(item => item.status === 'new').length)
    setPendingCount(ITEMS.items.filter(item => item.status === 'pending').length)


    if (items.length > 0 && !selectedVehicle) {
      setSelectedVehicle(items[0]);
    }

    if (e) {

      setTimeout(async () => {

        const { items } = await Api.get<IPaginationResponse<Vehicle>, IPaginationOptions>(Endpoint.VEHICLE_LIST, { page: 0, limit, group, status, search, isDeleted });
        if (items) {
          setVehicles(items);
        }
        const item = items.filter((item:any)=> item.id == selectedVehicle?.id)
        setSelectedVehicle(item[0]);
      }, 1000)
    }
 })

  useEffect(() => { refresh(); }, [search, activeGroupFilter, limit, activeStatusFilter, selectedVehicle]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteVehicle = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.VEHICLE, { id: selectedVehicle?.id });
    setSelectedVehicle(undefined);
    refresh();
  }

  const getProfilePic = (vehicle: Vehicle, type: ProfilePicSize): string => {
    if (vehicle.plateFile)
      return `${getStorageUrl()}/vehicle/${vehicle.id}/plate/${vehicle.plateFile?.id}/${type}.${vehicle.plateFile?.extension}`;
    else {
      return vehicleImg;
    }
  }

  const getModel = (vehicle: Vehicle): string => {
    return [vehicle.year, vehicle.make, vehicle.model].join(' ');
  }

  const toListItems = (vehicle?: Vehicle): ListItem<Vehicle> | undefined => {
    if (!vehicle) {
      return;
    }
    return {
      id: vehicle.id as string,
      title: vehicle.plate,
      imgSrc: getProfilePic(vehicle, ProfilePicSize.Single),
      status: ListItemStatus.Green,
      deleted: vehicle.deletedTime,
      ref: vehicle
    }
  }

  const addVehicle = () => {
    setFormMode(FormMode.Adding);
  }

  const editVehicle = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (vehicles) {
      const currentVehicleIndex = vehicles.findIndex(vehicle => !!selectedVehicle && vehicle.id === selectedVehicle.id);
      if (currentVehicleIndex + 1 < vehicles.length && currentVehicleIndex !== -1) {
        setSelectedVehicle(vehicles[currentVehicleIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (vehicles) {
      const currentVehicleIndex = vehicles.findIndex(vehicle => !!selectedVehicle && vehicle.id === selectedVehicle.id);
      if (currentVehicleIndex - 1 > -1 && currentVehicleIndex !== -1) {
        setSelectedVehicle(vehicles[currentVehicleIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedVehicle(undefined);
    setActiveGroupFilter(target);
  }

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedVehicle(undefined);
    setActiveStatusFilter(target?.title !== activeStatusFilter?.title ? target : undefined);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'all', icon: 'phone-21', count: allCount },
                  { title: 'pending', icon: 'ui-22', count: pendingCount },
                  { title: 'new', icon: 'documents-03', count: newCount },
                  { title: "active", icon: "user-check", count: activeCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
                activeStatusFilter={activeStatusFilter}
                onStatusSelect={selectStatus}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                items={vehicles?.map(toListItems) as ListItem<Vehicle>[]}
                activeItem={toListItems(selectedVehicle)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setVehicles(undefined); refresh(); }}
                onClick={(item: ListItem<Vehicle>) => { setSelectedVehicle(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                vehicles !== undefined ? (
                  selectedVehicle ? (
                    <View
                      componentName={"vehicle"}
                      showAdd={true}
                      showAudit={true}
                      showEdit={true}
                      showDelete={selectedVehicle.deletedTime ? false : true}
                      showNext={true}
                      showPrev={true}
                      onAdd={addVehicle}
                      onEdit={editVehicle}
                      onDelete={deleteVehicle}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedVehicle.id)}
                    >
                      <SelectedItemContent
                        key={selectedVehicle.id}
                        selectedVehicle={selectedVehicle}
                        getModel={getModel}
                        getProfilePic={getProfilePic}
                        onRefresh={refresh}
                        setSelectedVehicle={setSelectedVehicle}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addVehicle} className="btn btn-primary text-bold">{t("mainMenu.fleet.addVehicle")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("mainMenu.fleet.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedVehicle ? (
                  <VehiclePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedVehicle.id}
                    onAfterSave={() => refresh(true)}
                  />
                ) : (
                  <VehiclePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh(true)}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: 'all', icon: 'phone-21', count: allCount },
              { title: 'pending', icon: 'ui-22', count: pendingCount },
              { title: 'new', icon: 'documents-03', count: newCount },
              { title: "active", icon: "user-check", count: activeCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
            activeStatusFilter={activeStatusFilter}
            onStatusSelect={selectStatus}
          />
          <ListItems
            items={vehicles?.map(toListItems) as ListItem<Vehicle>[]}
            activeItem={toListItems(selectedVehicle)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setVehicles(undefined); refresh(); }}
            onClick={(item: ListItem<Vehicle>) => { setSelectedVehicle(item.ref); }}
          />
          {formMode === undefined ? (
            vehicles !== undefined ? (
              selectedVehicle ? (
                <View
                  componentName={"vehicle"}
                  showAdd={true}
                  showAudit={true}
                  showEdit={true}
                  showDelete={selectedVehicle.deletedTime ? false : true}
                  showNext={true}
                  showPrev={true}
                  onAdd={addVehicle}
                  onEdit={editVehicle}
                  onDelete={deleteVehicle}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedVehicle.id)}
                  childClassName={"p-0"}
                >
                  <SelectedItemContent
                    key={selectedVehicle.id}
                    selectedVehicle={selectedVehicle}
                    getModel={getModel}
                    getProfilePic={getProfilePic}
                    onRefresh={refresh}
                    setSelectedVehicle={setSelectedVehicle}
                  />
                </View>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addVehicle} className="btn btn-primary text-bold">{t("mainMenu.fleet.addVehicle")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("mainMenu.fleet.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedVehicle ? (
              <VehiclePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedVehicle.id}
                onAfterSave={() => refresh(true)}
              />
            ) : (
              <VehiclePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh(true)}
              />
            )
          )}
        </List>
      }
    </div>
  );

}
