import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Service } from "../../../entities/service.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { getStorageUrl } from "../../../util/file.util";
import { ServicePost } from "../post.component";
import { SelectedItemContent } from "./content.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
  { title: "deleted", icon: "trash" },

];

export enum ProfilePicSize {
  List = "admin_large",
  Single = "admin_single",
}

export function ServiceList(props: any) {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [ErrorMessage, setErrorMessage] = useState<string>();
  const [limit, setLimit] = useState<number>(20);
  const [allCount, setAllCount] = useState<number>(0);
  const [count, setCount] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [services, setServices] = useState<Service[] | undefined>(undefined);
  const [selectedService, setSelectedService] = useState<Service>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [size, setSize] = useState<number>()
  const [deletedCount, setDeletedCount] = useState<number>(0);

  const { t } = useTranslation(['main', 'list']);

  const getProfilePic = (service: Service, type: ProfilePicSize): string | undefined => {
    console.log("Service: ", service)
    //service?.profileFiles?.id && 
    if (service?.profileFiles?.length > 0 && service?.id) {
      return `${getStorageUrl()}/service/${service.id}/profile/${service?.profileFiles[0]?.id
        }/${type}.${service?.profileFiles[0]?.extension}`;
    } else {
      return undefined;
    }
  };

  // useEffect(() => {
  //   setSelectedService(props.location.state)
  // }, [props.location.state])

  const refresh = useCallback(async () => {
    setErrorMessage(undefined);
    let group: string | undefined = activeGroupFilter?.title
    group = group === 'all' ? undefined : group;

    let isDeleted = false;
    if (group == "deleted") {
      group = undefined;
      isDeleted = true;
    }

    const { items, totalItems } = await Api.get<IPaginationResponse<Service>, IPaginationOptions>(Endpoint.SERVICE_LIST, { page: 0, limit, group, search, isDeleted });

    if (items && items.length !== totalItems) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }

    if (items) {
      if (props.location.state != null) {
        setSelectedService(props.location.state);
        setServices(items);
      }
      else {
        setServices(items);
      }

    }

    const response = await Api.get<any, any>(Endpoint.SERVICE_DELETED_COUNT);
    setDeletedCount(response);

    setAllCount(totalItems)

    if (items.length > 0 && !selectedService) {
      setSelectedService(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedService]);

  useEffect(() => { refresh(); }, [refresh]);

  const fetchMoreData = () => {
    setLimit(limit + 10);
  };

  const deleteService = async () => {
    if (selectedService?.isDefault) {
      setErrorMessage('Default service can not be deleted!');
    }
    else {
      setConfirmingDelete(false); setErrorMessage(undefined);
      await Api.delete(Endpoint.SERVICE, { id: selectedService?.id });
      setSelectedService(undefined);
      refresh();
    }
  }

  const toListItems = (service?: Service): ListItem<Service> | undefined => {
    if (!service) {
      return;
    }
    return {
      id: service.id as string,
      title: `${service.title}${service.isDefault ? ' (default)' : ''}`,
      ref: service,
      deleted: service.deletedTime,

    }

  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (services) {
      const currentServicesIndex = services.findIndex(service => !!selectedService && service.id === selectedService.id);
      if (currentServicesIndex + 1 < services.length && currentServicesIndex !== -1) {
        setSelectedService(services[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedService(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (services) {
      const currentServiceIndex = services.findIndex(service => !!selectedService && service.id === selectedService.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedService(services[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  let history = useHistory();
  function checkAudit(id: string | undefined): void | undefined {
    history.push(`/audit/${id}`);
  }

  const updateSize = () => {
    setSize(window.screen.width)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])

  return (
    <div data-testid='upper'>
      {size && size < 1000 ?
        <List>
          <div className="row">
            <div className="col-xl-12">
              <ListFilter
                groupFilters={[
                  { title: 'All', icon: 'phone-21', count: allCount },
                  { title: "deleted", icon: "trash", count: deletedCount },
                ]}
                activeGroupFilter={activeGroupFilter}
                onGroupSelect={selectGroup}
              />
            </div>
            <div className="col-xl-12">
              <ListItems
                data-testid='list-item'
                items={services?.map(toListItems) as ListItem<Service>[]}
                activeItem={toListItems(selectedService)}
                onSearchChange={onSearchChange}
                showSearch={true}
                showRefresh={true}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                onRefresh={() => { setServices(undefined); refresh(); }}
                onClick={(item: ListItem<Service>) => { setSelectedService(item.ref); }}
              />
            </div>
            <div className="col-xl-12" style={{ marginTop: "40px" }}>
              {formMode === undefined ? (
                services !== undefined ? (
                  selectedService ? (
                    <View
                      componentName={"service"}
                      showAdd={true}
                      showEdit={true}
                      showDelete={true}
                      showNext={true}
                      showPrev={true}
                      showAudit={true}
                      onAdd={addService}
                      onEdit={editService}
                      onDelete={deleteService}
                      onPrev={onPrev}
                      onNext={onNext}
                      onAudit={() => checkAudit(selectedService.id)}

                    >
                      <SelectedItemContent
                        selectedService={selectedService}
                        setSelectedService={setSelectedService}
                        onRefresh={refresh}
                        errorMessage={ErrorMessage}
                        getProfilePic={getProfilePic}
                      />
                    </View>
                  ) : (
                    <div className="mx-auto py-5 text-center">
                      <button onClick={addService} className="btn btn-primary text-bold">{t("list:service.addService")}</button>
                    </div>
                  )
                ) : (
                  <div className="mx-auto py-5 text-center">
                    <p>{t("list:service.wait")}</p>
                    <Loading loading={true} />
                  </div>
                )
              ) : (
                selectedService ? (
                  <ServicePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    entityId={selectedService.id}
                    onAfterSave={() => refresh()}
                  />
                ) : (
                  <ServicePost
                    style={FormStyle.Containerized}
                    formMode={formMode}
                    cancel={cancelEditing}
                    onAfterSave={() => refresh()}
                  />
                )
              )}
            </div>
          </div>
        </List> :
        <List>
          <ListFilter
            groupFilters={[
              { title: "All", icon: "phone-21", count: allCount },
              { title: "deleted", icon: "trash", count: deletedCount },
            ]}
            activeGroupFilter={activeGroupFilter}
            onGroupSelect={selectGroup}
          />
          <ListItems
            data-testid='list-item'
            items={services?.map(toListItems) as ListItem<Service>[]}
            activeItem={toListItems(selectedService)}
            onSearchChange={onSearchChange}
            showSearch={true}
            showRefresh={true}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            onRefresh={() => { setServices(undefined); refresh(); }}
            onClick={(item: ListItem<Service>) => { setSelectedService(item.ref); }}
          />
          {formMode === undefined ? (
            services !== undefined ? (
              selectedService ? (<>
                <View
                  componentName={"service"}
                  showAdd={true}
                  showEdit={true}
                  showDelete={true}
                  showNext={true}
                  showPrev={true}
                  showAudit={true}
                  onAdd={addService}
                  onEdit={editService}
                  onDelete={deleteService}
                  onPrev={onPrev}
                  onNext={onNext}
                  onAudit={() => checkAudit(selectedService.id)}

                >
                  <SelectedItemContent
                    selectedService={selectedService}
                    onRefresh={refresh}
                    setSelectedService={setSelectedService}
                    errorMessage={ErrorMessage}
                    getProfilePic={getProfilePic}
                  />
                </View></>
              ) : (
                <div className="mx-auto py-5 text-center">
                  <button onClick={addService} className="btn btn-primary text-bold">{t("list:service.addService")}</button>
                </div>
              )
            ) : (
              <div className="mx-auto py-5 text-center">
                <p>{t("list:service.wait")}</p>
                <Loading loading={true} />
              </div>
            )
          ) : (
            selectedService ? (
              <ServicePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                entityId={selectedService.id}
                onAfterSave={() => refresh()}
              />
            ) : (
              <ServicePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
          )}
        </List>
      }

    </div>
  );

}
