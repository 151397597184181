import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const pdf = require(`../../img/pdf.png`);

export interface File {
  thumb: string,
  full: string,
  type?: string,
}

export interface FileGroup {
  title: string;
  files?: File[];
}

interface Props {
  files?: File[];
  groups?: FileGroup[];
}

export function ViewContentFiles({ files }: Props) {

  useEffect(() => {

    if (!files) {
      return;
    }
    const _groups: FileGroup[] = []
    for (const file of files) {
      const group = _groups.find(g => g.title === file.type);
      if (group) {
        if (!group.files) {
          group.files = [];
        }
        group.files.push(file);
      } else {
        _groups.push({ title: file.type as string, files: [file] });
      }
    }
    // filter out the Driver Pic group
    const filteredGroups = _groups.filter(g => g.title !== 'Driver Pic');
    setDisplayGroups(filteredGroups)

  }, []);
  
  let expandedImgRef!: HTMLImageElement | null;
  const [displayGroups, setDisplayGroups] = useState<FileGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<FileGroup>(displayGroups[0]);
  
  const [count, setCount] = useState<number>(0);
  const [expanded, setExpanded] = useState<string>();
  
  
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);
  
  const onClick = (url: string) => {
    setExpanded(url);
  }

  const onPdfClick = (url: string) => {
    window.open(url.replace('/admin_large.pdf', '.pdf'));
  }

  function getGroupTitle(title: string): string {
    return title
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  }

  //   useEffect(()=>{
  //     if(count==0){
  // setSelectedGroup(groups[0])
  //   }else{ 
  //     setSelectedGroup(selectedGroup);}

  //   },[groups])


  // const GroupRefresh = useCallback(()=>{

  //   setSelectedGroup(groups[0]);

  // });
  return (
    <div style={{ maxWidth: '768px' }}>
      <div className="os-tabs-controls">
        <div className='row'>
          <div className="col">
            <ul className="nav nav-tabs" style={{ flexWrap: "wrap" }}>
              {displayGroups.map(group => (
                <li key={group.title} className="nav-item">
                  <button
                    className={classNames('nav-link', { active: group.title === selectedGroup?.title })}
                    data-toggle="tab"
                    onClick={() => {
                      setSelectedGroup(group);
                      return false
                    }}
                  >
                    {group.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>

        </div>

      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {selectedGroup?.files?.map((file, index) =>
          file.thumb.includes('admin_single.pdf') ?
            <img key={index} src={pdf} style={{ cursor: 'pointer' }}
              alt={index.toString()} onClick={() => onPdfClick(file.full)}
            /> :
            <img className="mx-2" key={index} style={{ maxWidth: '200px', cursor: 'pointer' }} src={file.thumb} alt={index.toString()}
              onClick={() => onClick(file.full)} />
        )}
      </div>
      {expanded && (
        <div>
          <img
            ref={r => expandedImgRef = r}
            className="expanded-img"
            src={expanded}
            alt=''
          />
          <div className="expanded-backdrop"></div>
        </div>)}
    </div>
  );
}