import { ModuleName } from '../../enums/core.enum';
import { AuthRoute } from '../../pages/auth/routes';
import { DashboardAclCode } from '../../pages/dashboard/acl';
import { DashboardRoute } from '../../pages/dashboard/routes';
import { DriverAclCode } from '../../pages/driver/acl';
import { DriverRoute } from '../../pages/driver/routes';
import { CallAclCode } from '../../pages/call/acl';
import { CallRoute } from '../../pages/call/routes';
import { FareAclCode } from '../../pages/fare/acl';
import { FareRoute } from '../../pages/fare/routes';
import { FeatureAclCode } from '../../pages/feature/acl';
import { FeatureRoute } from '../../pages/feature/routes';
import { FleetAclCode } from '../../pages/fleet/acl';
import { FleetRoute } from '../../pages/fleet/routes';
import { GroupAclCode } from '../../pages/group/acl';
import { GroupRoute } from '../../pages/group/routes';
import { InspectionAclCode } from '../../pages/inspection/acl';
import { InspectionRoute } from '../../pages/inspection/routes';
import { NetworkAclCode } from '../../pages/network/acl';
import { NetworkRoute } from '../../pages/network/routes';
import { OperatorAclCode } from '../../pages/operator/acl';
import { OperatorRoute } from '../../pages/operator/routes';
import { PassengerRoute } from '../../pages/passenger/routes';
import { ReportAclCode } from '../../pages/report/acl';
import { SecurityAclCode } from '../../pages/security/acl';
import { SecurityRoute } from '../../pages/security/routes';
import { ServiceRoute } from '../../pages/service/routes';
import { SettingsAclCode } from '../../pages/settings/acl';
import { SettingsRoute } from '../../pages/settings/routes';
import { DispatchAclCode } from '../../pages/support/acl';
import { UserAclCode } from '../../pages/user/acl';
import { UserRoute } from '../../pages/user/routes';
import { VehicleAclCode } from '../../pages/vehicle/acl';
import { VehicleRoute } from '../../pages/vehicle/routes';
import { ZoneRoute } from '../../pages/zone/routes';
import { MenuItem } from './header.interface';
import { CorporateBookingRoute } from '../../pages/corporate/routes';
import { CorporateBookingAclCode } from '../../pages/corporate/acl';
import { RequestRoute } from '../../pages/request/routes';
import { RequestAclCode } from '../../pages/request/acl';
import { RequestCategoryRoute } from '../../pages/request-category/routes';
import { RequestCategoryAclCode } from '../../pages/request-category/acl';
import { CustomTollsRoute } from '../../pages/custom-tolls/routes';
import { CustomTollsAclCode } from '../../pages/custom-tolls/acl';
import { PassengerAclCode } from '../../pages/passenger/acl';
import { ArticleAcl, ArticleAclCode } from '../../pages/article/acl';
import { HotspotAclCode } from '../../pages/hotspot/acl';
import { SubscriberAcl, SubscriberAclCode } from '../../pages/subscribers/acl';
import { ZoneAclCode } from '../../pages/zone/acl';
import { ServiceAclCode } from '../../pages/service/acl';

export default [
  {
    title: 'mainMenu.main.dashboard',
    link: DashboardRoute.Overview,
    module: ModuleName.Dashboard,
    icon: 'layout',
    submenu: [
      [
        {
          title: 'mainMenu.dashboard.overview',
          link: DashboardRoute.Overview,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Overview,
        },
        {
          title: 'mainMenu.dashboard.revenue',
          link: DashboardRoute.Revenue,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Revenue,
        },
        {
          title: 'mainMenu.dashboard.map',
          link: DashboardRoute.Map,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Map,
        },
        {
          title: 'mainMenu.dashboard.bookings',
          link: DashboardRoute.Bookings,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Booking,
        },
        {
          title: 'mainMenu.dashboard.trips',
          link: DashboardRoute.Trips,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Trip,
        },
        {
          title: 'mainMenu.dashboard.shifts',
          link: DashboardRoute.Sessions,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Session,
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.fleet',
    link: DriverRoute.List,
    module: [
      ModuleName.Driver,
      ModuleName.Vehicle,
      ModuleName.Operator,
      ModuleName.Fleet,
      ModuleName.Network,
      ModuleName.Group,
      ModuleName.Feature,
      ModuleName.Call
    ],
    icon: 'truck',
    submenu: [
      [
        {
          title: 'mainMenu.fleet.drivers',
          link: DriverRoute.List,
          module: ModuleName.Driver,
          code: [DriverAclCode.ListAll, DriverAclCode.ListOwn, DriverAclCode.Create],
        },
        {
          title: 'mainMenu.fleet.vehicles',
          link: VehicleRoute.List,
          module: ModuleName.Vehicle,
          code: [VehicleAclCode.ListAll, VehicleAclCode.ListOwn, VehicleAclCode.Create],
        },

        {
          title: 'mainMenu.fleet.features',
          link: FeatureRoute.List,
          module: ModuleName.Feature,
          code: [FeatureAclCode.ListAll, FeatureAclCode.Create],
        },
        {
          title: 'mainMenu.fleet.inspection',
          link: InspectionRoute.Post,
          module: ModuleName.Inspection,
          code: InspectionAclCode.Update,
        },
      ],
      [
        {
          title: 'mainMenu.fleet.networks',
          link: NetworkRoute.List,
          module: ModuleName.Network,
          code: [NetworkAclCode.ListAll, NetworkAclCode.ListOwn, NetworkAclCode.Create],
        },

        {
          title: 'mainMenu.fleet.operators',
          link: OperatorRoute.List,
          module: ModuleName.Operator,
          code: [OperatorAclCode.ListAll, OperatorAclCode.ListOwn, OperatorAclCode.Create],
        },
        {
          title: 'mainMenu.fleet.fleets',
          link: FleetRoute.List,
          module: ModuleName.Fleet,
          code: [FleetAclCode.ListAll, FleetAclCode.ListOwn, FleetAclCode.Create],
        },
        {
          title: 'mainMenu.fleet.groups',
          link: GroupRoute.List,
          module: ModuleName.Group,
          code: [GroupAclCode.ListAll, GroupAclCode.ListOwn, GroupAclCode.Create],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.support',
    link: '/dispatch/dashboard',
    module: [ModuleName.Dispatch, ModuleName.Call],
    icon: 'phone-call',
    submenu: [
      [{
        title: 'mainMenu.main.dispatch',
        link: '/dispatch/dashboard',
        module: ModuleName.Dispatch,
        code: DispatchAclCode.View,
      },
      {
        title: 'mainMenu.fleet.history',
        link: CallRoute.List,
        module: ModuleName.Call,
        code: [CallAclCode.ListAll, CallAclCode.ListOwn, CallAclCode.Delete],
      }, {
        title: 'mainMenu.main.request',
        link: RequestRoute.List,
        module: ModuleName.Request,
        code: [RequestAclCode.ListAll, RequestAclCode.ListOwn, RequestAclCode.Delete],
      }, {
        title: 'mainMenu.main.requestCategory',
        link: RequestCategoryRoute.List,
        module: ModuleName.RequestCategory,
        code: [RequestCategoryAclCode.ListAll, RequestCategoryAclCode.ListOwn, RequestCategoryAclCode.Delete],
      },]
    ]
  },
  {
    title: 'mainMenu.main.website',
    link: '#',
    module: [ModuleName.Article,ModuleName.Subscriber, ModuleName.Hotspot],
    icon: 'book',
    submenu: [
      [
        {
          title: 'mainMenu.website.article',
          link: '/article/list',
          module: ModuleName.Article,
          code: [ArticleAclCode.ListAll,ArticleAclCode.ListOwn,ArticleAclCode.Update,ArticleAclCode.Create,ArticleAclCode.Delete,ArticleAclCode.Audit],
        },
        {
          title: 'mainMenu.website.subscriber',
          link: '/subscriber/list',
          module: ModuleName.Subscriber,
          code: [SubscriberAclCode.ListAll,SubscriberAclCode.ListOwn,SubscriberAclCode.Update,SubscriberAclCode.Create,SubscriberAclCode.Delete,SubscriberAclCode.Audit],
        },
        {
          title: 'mainMenu.website.hotspot',
          link: '/hotspot/list',
          module: ModuleName.Hotspot,
          code: [HotspotAclCode.ListAll,HotspotAclCode.ListOwn,HotspotAclCode.Update,HotspotAclCode.Create,HotspotAclCode.Delete,HotspotAclCode.Audit],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.security',
    link: SecurityRoute.IncidentList,
    module: ModuleName.Security,
    icon: 'others-43',
    submenu: [
      [
        {
          title: 'mainMenu.security.incidents',
          link: SecurityRoute.IncidentList,
          module: ModuleName.Security,
          code: SecurityAclCode.IncidentList,
        },
        {
          title: 'mainMenu.security.videoMonitoring',
          link: SecurityRoute.VideoMonitoring,
          module: ModuleName.Security,
          code: SecurityAclCode.VideoMonitoring,
        },
        {
          title: 'mainMenu.security.trackVehicle',
          link: SecurityRoute.BookingTrackVehicleWithoutCode,
          module: ModuleName.Security,
          code: SecurityAclCode.TrackVehicle,
        },
        {
          title: 'mainMenu.security.protocols',
          link: SecurityRoute.ProtocolList,
          module: ModuleName.Security,
          code: SecurityAclCode.Protocols,
        },
        {
          title: 'mainMenu.security.emergencyList',
          link: SecurityRoute.EmergencyList,
          module: ModuleName.Security,
          code: SecurityAclCode.EmergencyList
        }
      ],
    ],
  },
  {
    title: 'mainMenu.main.fares',
    link: '#',
    module: [ModuleName.Fare,ModuleName.Zone,ModuleName.Service,ModuleName.CustomTolls],
    icon: 'ui-55',
    submenu: [
      [
        {
          title: 'mainMenu.fares.fareDashboard',
          link: FareRoute.Dashboard,
          module: ModuleName.Fare,
          code: Object.values(FareAclCode),
        },
        {
          title: 'mainMenu.fares.zones',
          link: ZoneRoute.List,
          module: ModuleName.Zone,
          code: Object.values(ZoneAclCode),
        },
        {
          title: 'mainMenu.fares.Services',
          link: ServiceRoute.List,
          module: ModuleName.Service,
          code: Object.values(ServiceAclCode),
        },
        {
          title: 'mainMenu.main.fares',
          link: FareRoute.List,
          module: ModuleName.Fare,
          code: Object.values(FareAclCode),
        },
        {
          title: 'mainMenu.main.customTolls',
          link: CustomTollsRoute.List,
          module: ModuleName.CustomTolls,
          code: Object.values(CustomTollsAclCode),
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.passengers',
    link: PassengerRoute.List,
    module: ModuleName.Passenger,
    icon: 'ui-93',
  },
  {
    title: 'mainMenu.accounting.reports',
    link: '/report/sheet',
    module: ModuleName.Report,
    icon: 'list',
    submenu: [
      [
        {
          title: 'mainMenu.accounting.transaction',
          link: '/report/sheet',
          module: ModuleName.Report,
          code: ReportAclCode.BalanceSheet,
        },
        {
          title: 'mainMenu.accounting.rides',
          link: '/report/ride',
          module: ModuleName.Report,
          code: ReportAclCode.Ride,
        },
        {
          title: 'mainMenu.accounting.shift',
          link: '/report/shift',
          module: ModuleName.Report,
          code: ReportAclCode.Shift,
        },
        {
          title: 'mainMenu.accounting.gps',
          link: '/report/gps',
          module: ModuleName.Report,
          code: ReportAclCode.Gps,
        },
        {
          title: 'mainMenu.accounting.zone',
          link: '/report/zone',
          module: ModuleName.Report,
          code: ReportAclCode.Zone,
        }

      ],
    ],
  },
  {
    title: 'mainMenu.main.audit',
    link: '/audit/:id',
    module: ModuleName.Audit,
    icon: 'folder',
  },
  {
    title: 'mainMenu.main.users',
    link: UserRoute.List,
    module: ModuleName.User,
    icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.users.users',
          link: UserRoute.List,
          module: ModuleName.User,
          code: [UserAclCode.ListAll, UserAclCode.ListOwn, UserAclCode.Create],
        },
        {
          title: 'mainMenu.users.roles',
          link: UserRoute.ListRole,
          module: ModuleName.User,
          code: [UserAclCode.ListAllRoles, UserAclCode.ListOwnRoles, UserAclCode.CreateRole],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.corporate',
    link: CorporateBookingRoute.Account,
    module: ModuleName.corporate,
    icon: 'briefcase',
    submenu: [
      [
        {
          title: 'mainMenu.corporateBooking.account',
          link: CorporateBookingRoute.Account,
          module: ModuleName.corporate,
          code: [CorporateBookingAclCode.ListAllAccount,CorporateBookingAclCode.ListOwnAccount],
        },   
        {
          title: 'mainMenu.corporateBooking.user',
          link: CorporateBookingRoute.User,
          module: ModuleName.corporate,
          code: [CorporateBookingAclCode.ListAllUser,CorporateBookingAclCode.ListOwnUser],
        },
        {
          title: 'mainMenu.corporateBooking.booking',
          link: CorporateBookingRoute.Booking,
          module: ModuleName.corporate,
          code: [CorporateBookingAclCode.ListOwnBooking],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.settings',
    link: SettingsRoute.General,
    module: ModuleName.Settings,
    icon: 'settings',
    submenu: [
      [
        {
          title: 'mainMenu.settings.general',
          link: SettingsRoute.General,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewGeneral, SettingsAclCode.UpdateGeneral],
        },
        // {
        //   title: 'mainMenu.settings.meter',
        //   link: SettingsRoute.Meter,
        //   module: ModuleName.Settings,
        //   code: [SettingsAclCode.ViewMeter, SettingsAclCode.UpdateMeter],
        // },
        // {
        //   title: 'mainMenu.settings.payment',
        //   link: SettingsRoute.Payment,
        //   module: ModuleName.Settings,
        //   code: [SettingsAclCode.ViewPayment, SettingsAclCode.UpdatePayment],
        // }, 
        {
          title: 'mainMenu.settings.shifts',
          link: SettingsRoute.Shift,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewShifts, SettingsAclCode.UpdateShifts],
        },
        {
          title: 'mainMenu.settings.penalties',
          link: SettingsRoute.Penalty,
          module: ModuleName.Settings,
          code: [
            SettingsAclCode.ViewPenalties,
            SettingsAclCode.UpdatePenalties,
          ],
        },
        {
          title: 'mainMenu.settings.payouts',
          link: SettingsRoute.Payout,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewShifts, SettingsAclCode.UpdateShifts],
        },
        {
          title: 'mainMenu.settings.consent',
          link: SettingsRoute.Consent,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewConsent, SettingsAclCode.UpdateConsent],
        },
        // {
        //   title: 'mainMenu.settings.notifications',
        //   link: SettingsRoute.Notifications,
        //   module: ModuleName.Settings,
        // },
        {
          title: 'mainMenu.settings.language',
          link: SettingsRoute.Language,
          module: ModuleName.Settings,
        }, {
          title: 'mainMenu.settings.currency',
          link: SettingsRoute.Currency,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewCurrency, SettingsAclCode.UpdateCurrency],
        }, {
          title: 'mainMenu.settings.driver',
          link: SettingsRoute.DriverRequirement,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewDriverRequirement],
        },
        {
          title: 'mainMenu.settings.vehicle',
          link: SettingsRoute.VehicleRequirement,
          module: ModuleName.Settings,
          code: [SettingsAclCode.ViewVehicleRequirement],
        }
      ],
    ],
  },
  {
    title: 'mainMenu.settings.logout',
    link: AuthRoute.Logout,
    module: ModuleName.User,
    isPublic: true,
    icon: 'log-out',
  },
] as MenuItem[];
