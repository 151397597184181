import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentFiles } from '../../../components/view/files.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { Currency } from '../../../entities/currency.entity';
import { Device } from '../../../entities/device.entity';
import { Driver, DriverStatus, StripeStatus } from '../../../entities/driver.entity';
import { DriverCheckList } from '../../../entities/driverCheckList.entity';
import { IFile } from '../../../entities/file.entity';
import { Inspection, InspectionType } from "../../../entities/inspection.entity";
import { InspectionAssignment, InspectionAssignmentStatus } from '../../../entities/inspectionAssignment.entity';
import { Role, RoleAssignment } from '../../../entities/role.entity';
import { Settings } from '../../../entities/settings.entity';
import { Transaction as _Transaction } from '../../../entities/transaction.entity';
import { Vehicle } from '../../../entities/vehicle.entity';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';
import { StatsFilterPeriod, StatsRequest, StatsResponse, getDatesForFilters } from '../../../interfaces/stats.interface';
import { Api, Endpoint } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { SettingsService } from '../../../services/settings.service';
import { dateFormate } from '../../../util/date.util';
import { getCurrencyFormatting } from '../../../util/distance';
import { getStorageUrl } from '../../../util/file.util';
import { capitalize } from '../../../util/string.util';
import { Checklist } from './checklist.component';
import { ProfilePicSize } from './list.component';
import { PayoutRequest, PayoutRequestStatus } from '../../../entities/payout-request.entity';

interface Transaction extends _Transaction {
    selected?: boolean;
}

enum ApproveButtonStatus {
    APPROVE = "approve",
    CONFIRM = "confirm",
    APPROVING = "approving"

}
interface Props {
    selectedDriver: Driver;
    getFullName: (driver: Driver) => string;
    getProfilePic: (driver: Driver, size: ProfilePicSize) => string;
    onApprove?: () => void,
    setSelectedDriver?: (driver: undefined | Driver,) => void
}
export function SelectedItemContent({ selectedDriver, getFullName, getProfilePic, onApprove, setSelectedDriver }: Props) {
    const { t } = useTranslation("forms");
    const translation = useTranslation("dashboard");

    const [driver, setDriver] = useState<Driver>(selectedDriver);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [paymentFilterPaidStatus, setPaymentFilterPaidStatus] = useState<'all' | 'paid' | 'unpaid'>('unpaid');
    const [paymentFilterPeriod, setPaymentFilterPeriod] = useState<StatsFilterPeriod>(StatsFilterPeriod.UntilYesterdayMidnight);
    const [payAmount, setPayAmount] = useState<number>(0);
    const [paySelectAll, setPaySelectAll] = useState<boolean>(true);
    const [payProvider, setPayProvider] = useState<string>('cash');
    const [payProviderRef, setPayProviderRef] = useState<string>();
    const [payoutConfirming, setPayoutConfirming] = useState<boolean>(false);
    const [payoutProcessing, setPayoutProcessing] = useState<boolean>(false);
    const [payoutProcessed, setPayoutProcessed] = useState<boolean>(false);
    const [pendingPayoutRequest, setPendingPayoutRequest] = useState<PayoutRequest>();
    const [payoutAssignedTime, setPayoutAssignedTime] = useState<string>();
    const [payoutMessage, setPayoutMessage] = useState<string>();
    const [payoutError, setPayoutError] = useState<string>();
    const [payoutUpdating, setPayoutUpdating] = useState<boolean>(false);
    const [payoutUpdated, setPayoutUpdated] = useState<boolean>(false);
    const [transactionsLoading, setTransactionsLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<StatsResponse>();
    const [time, setTime] = useState<StatsFilterPeriod>(StatsFilterPeriod.Today);
    const [deviceCode, setDeviceCode] = useState<string>();
    const [vehicleCode, setVehicleCode] = useState<string>();
    const [deviceAuthCode, setDeviceAuthCode] = useState<number>();
    const [error, setError] = useState<string>();
    const [depositAccess, setDepositAccess] = useState<boolean>();

    const [vehicles, setVehicles] = useState<Vehicle[]>([]);

    const [balanceAccess, setBalanceAccess] = useState<boolean>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [vehicleError, setVehicleError] = useState<string>();
    const [inspection, setInspection] = useState<Inspection[]>();
    const [open, setOpen] = useState(false);
    const [approveStatus, setApproveStatus] = useState<ApproveButtonStatus>(ApproveButtonStatus.APPROVE);
    const [inspectionAssignment, setInspectionAssignment] = useState<InspectionAssignment[]>([]);
    const [symbol, setSymbol] = useState<string | null>('')
    const [subUnit, setSubUnit] = useState<number>(100)
    const [depositAmount, setDepositAmount] = useState<number>()
    const [rentalAmount, setRentalAmount] = useState<number>()
    const [sendingRentalAgreement, setSendingRentalAgreement] = useState(false);
    const [rentalError, setRentalError] = useState<string>();
    const [rentalSuccess, setRentalSuccess] = useState<string>();
    const [selectedRentalVehicleId, setSelectedRentalVehicleId] = useState<string>()
    const [paymentType, setPaymentType] = useState<string>("cash");
    const [confirmingApproval, setConfirmingApproval] = useState(false);
    const [approved, setApproved] = useState(false);
    const [approveMessage, setApproveMessage] = useState([])
    const [confirmSendInvite, setConfirmSendInvite] = useState(false);
    const [sendingInvite, setSendingInvite] = useState(false);
    const [sentInvite, setSentInvite] = useState(false);
    const [selectedTransactionType, setSelectedTransactionType] = useState<'payments' | 'payouts'>('payments');
    // const [identityfiles,setIdentityFiles]  = useState<any>([]);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const payexternal = async () => {
        const { items } = await Api.get<any, any>(Endpoint.DRIVER_PAY, { id: selectedDriver?.id });
    }
    const _handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onGetDeviceCode();
        }
    }

    const _handleKeyDownDeposit = (e: any) => {
        if (e.key === 'Enter') {
            onGetDeposit();
        }
    }

    const ref = useCallback(() => {
        const refresh = async () => {
            const { items } = await Api.get<IPaginationResponse<Inspection>, IPaginationOptions>(Endpoint.INSPECTION_LIST, { page: 0, limit: 20, });
            if (items) {
                const data = items.filter(items => (items.type == InspectionType.Driver))
                setInspection(data);
                setInspectionAssignment([]);

                data?.forEach((DriverInspectionData) => {
                    let finalStatus: InspectionAssignmentStatus = InspectionAssignmentStatus.Pending;

                    setInspectionAssignment((items) => {
                        selectedDriver?.driverCheckList?.forEach((InspectionData) => {
                            if (InspectionData.checklistItem == DriverInspectionData.title) {
                                finalStatus = InspectionAssignmentStatus.Done;
                            }
                        })
                        return ([...items, {
                            id: selectedDriver?.id,
                            inspection: DriverInspectionData,
                            entity: 'Driver',
                            status: finalStatus,
                        }])
                    })
                })
            }
        };
        refresh();
        //   setInspectionAssignment()
    }, [selectedDriver, inspectionAssignment]);
    const {
        identityFiles,
        registrationFiles,
        insuranceFiles
    } = selectedDriver;

    const onGetDeviceCode = async () => {
        if (!deviceCode) {
            setError('Invalid Device Code');
        }
        else if (!vehicleCode) {
            setError(undefined);
            setVehicleError('Invalid Vehicle Code')
        }
        else {
            try {
                setVehicleError(undefined);
                setDeviceAuthCode(undefined)
                setError(undefined)
                const response: any = await Api.get<Device, { code: string, id: string, vehicleCode: string }>(Endpoint.DRIVER_Device_Get_AUTH, { code: deviceCode.toUpperCase(), id: selectedDriver.id as string, vehicleCode: vehicleCode.toUpperCase() });
                setDeviceAuthCode(response.authCode)

            } catch (error: any) {
                setDeviceAuthCode(undefined)
                setError(error);
                console.log('Error Getting Device Auth Code:', error);
            }
        }
    }
    const onGetDeposit = async () => {
        setSuccessMessage("")
        setError("")
        if (!depositAmount || depositAmount == 0) {
            setError("Please Enter Amount")
        }
        else {
            const response = await Api.post<any, any>(Endpoint.TRANSACTION_DEPOSIT, { driverId: selectedDriver.id as string, amount: Math.floor((depositAmount * subUnit)), paymentType });
            if (!response) {
                setError("Something is wrong !")
            }
            setSuccessMessage("Sucessfully Deposit")
            setDepositAmount(0);
            init()
        }
    }

    const onSendAgreement = async () => {
        setSendingRentalAgreement(true);

        if (!rentalAmount || rentalAmount == 0) {
            setRentalError("Please enter a weekly rental amount");
            setSendingRentalAgreement(false);
            return;
        }
        if (!selectedRentalVehicleId) {
            setRentalError("Please select a rental vehicle");
            setSendingRentalAgreement(false);
            return;
        }

        try {
            await Api.post<any, any>(Endpoint.DRIVER_RENTAL_AGREEMENT, {
                driverId: selectedDriver.id as string,
                vehicleId: selectedRentalVehicleId,
                amount: Math.floor((rentalAmount * subUnit)),
            });
        } catch (err) {
            setRentalError("Failed to send agreement");
            setSendingRentalAgreement(false);
            return;
        }
        setRentalSuccess("The agreement has been generated and sent over the driver.")
        setSendingRentalAgreement(false);
        setRentalAmount(undefined);
        setSelectedRentalVehicleId(undefined);
        init();
    }

    const onSendInviteSMS = async () => {
        setConfirmSendInvite(false);
        setSendingInvite(true)
        const response = await Api.post<any, any>(Endpoint.ENROLL_INVITE, { driverId: selectedDriver.id as string });
        setSendingInvite(false);
        setSentInvite(true);
    }

    const onSendPayout = async () => {
        setPayoutError(undefined);
        setPayoutConfirming(false);
        setPayoutProcessing(true);
        setPayoutProcessed(false);

        try {
            await Api.post<Transaction, { driverId: string, amount: number, provider: string, providerPaymentId?: string, transactionIds: string[] }>(
                Endpoint.DRIVER_PAYOUT, {
                    driverId: selectedDriver.id as string,
                    amount: Math.floor((payAmount * subUnit)),
                    provider: payProvider,
                    providerPaymentId: payProviderRef,
                    transactionIds: transactions.filter((transaction: Transaction) => transaction.selected).map((transaction: Transaction) => transaction.id as string),
                });
        } catch (err) {
            setPayoutError((err as Error).message);
            setPayoutConfirming(false);
            setPayoutProcessing(false);
            setPayoutProcessed(false);
            return;
        }

        setPayoutProcessing(false);
        setPayoutProcessed(true);

        setSelectedTransactionType('payouts');

        setTimeout(() => {
            setPayoutConfirming(false);
            setPayoutProcessing(false);
            setPayoutProcessed(false);
        }, 5000);
    }

    const onConfirmPayout = async () => {
        setPayoutConfirming(true);
        setPayoutProcessing(false);
        setPayoutProcessed(false);
    }

    const onCancelPayout = () => {
        setPayoutConfirming(false);
        setPayoutProcessing(false);
        setPayoutProcessed(false);
    }

    const init = useCallback(async () => {
        // setDriver(selectedDriver);
        const _driver = await Api.get<Driver, { id: string }>(Endpoint.DRIVER, { id: selectedDriver.id as string });
        setDriver(_driver);
        setApproveMessage(driver?.stripeMessage)
        const pendingPayout = await Api.get<PayoutRequest, { id: string }>(Endpoint.DRIVER_PAYOUT_PENDING, { id: selectedDriver.id as string });
        setPendingPayoutRequest(pendingPayout);
        const vehicleList = await Api.get<IPaginationResponse<Vehicle>, IPaginationOptions>(Endpoint.VEHICLE_LIST, { limit: 100 });
        setVehicles(vehicleList.items);
    }, [selectedDriver]);

    const refreshTransactions = useCallback(async () => {
        setTransactionsLoading(true);
        const isPaid = paymentFilterPaidStatus === 'all' ? undefined : paymentFilterPaidStatus === 'paid';
        let startTime, endTime;
        if (selectedTransactionType === 'payments') {
            const { startDate, endDate } = getDatesForFilters(paymentFilterPeriod);
            startTime = startDate;
            endTime = endDate;
        }
        const _transactions = await Api.get<Transaction[], { id: string, startTime?: Date, endTime?: Date, type: 'payments' | 'payouts', isPaid: boolean | undefined }>(
            Endpoint.DRIVER_TRANSACTIONS, { id: selectedDriver.id as string, type: selectedTransactionType, startTime, endTime, isPaid });
        setTransactions(_transactions.map((transaction: Transaction) => {
            transaction.selected = true;
            return transaction;
        }));
        setTransactionsLoading(false);
    }, [selectedDriver, paymentFilterPaidStatus, paymentFilterPeriod, selectedTransactionType]);

    useEffect(() => {
        updatePayAmount();
    }, [transactions]);

    const toggleTransactionSelect = ((id: string) => {
        setTransactions(transactions.map((transaction: Transaction) => {
            if (transaction.id === id) {
                transaction.selected = !transaction.selected;
            }
            return transaction;
        }))
    });

    const updatePayAmount = () => {
        let _payAmount = 0;
        if (paymentFilterPaidStatus === 'unpaid') {
            transactions.forEach((transaction: Transaction) => {
                if (transaction.selected) {
                    _payAmount += transaction.earning;
                }
            });
        }
        _payAmount = toSubUnit(_payAmount);
        setPayAmount(_payAmount);
    }

    const toSubUnit = (value: number): number => {
        const settings: Settings | undefined = SettingsService.getSetting();
        // const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
        const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
        return value / unit;
    }
    const newVal = async (i: InspectionAssignment) => {
        if (i?.status === InspectionAssignmentStatus.Done) {
            if (i.inspection) {
                const driverCheckListData: DriverCheckList = { driver: selectedDriver?.id as string, checklistItem: i.inspection.title, checked: true }
                selectedDriver.driverCheckList.push(driverCheckListData);
                //create aa seprate endpoint for driver driverCheckList Data
                await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, driverCheckList: selectedDriver.driverCheckList });
            }
        }
        else if (i.status === InspectionAssignmentStatus.Pending) {
            selectedDriver.driverCheckList = selectedDriver.driverCheckList.filter(item => item.checklistItem !== i.inspection?.title)
            await Api.patch(Endpoint.DRIVER, { id: selectedDriver.id, driverCheckList: selectedDriver.driverCheckList });
        }
        ref();
    }
    const getLocaleDateOfBirth = (driver: Driver | undefined): string => {
        return driver && driver.dateOfBirth ? new Date(driver.dateOfBirth).toLocaleDateString() : '';
    }
    const getLocaleDateOfConsent = (driver: Driver | undefined): string => {
        return driver && driver.createTime ? new Date(driver.createTime).toLocaleDateString() : '';
    }
    const getFileSrc = (file: IFile): { thumb: string, full: string, type?: string } => {
        const files = {
            thumb: `${getStorageUrl()}/${file.fileType?.entity}/${driver?.id}/${file.fileType?.id}/${file.id}/admin_single.${file.extension}`,
            full: `${getStorageUrl()}/${file.fileType?.entity}/${driver?.id}/${file.fileType?.id}/${file.id}/admin_large.${file.extension}`,
            type: file.fileType?.name,
        }
        return files
    }

    const onUpdatePayout = async (status: PayoutRequestStatus) => {
        setPayoutUpdating(true);
        setPayoutUpdated(false);
        
        await Api.patch<void, Partial<PayoutRequest>>(Endpoint.DRIVER_PAYOUT, {
            id: pendingPayoutRequest?.id as string,
            status,
            assignedTime: payoutAssignedTime ? new Date(payoutAssignedTime) : undefined,
            message: payoutMessage,
        });
        
        setPendingPayoutRequest(undefined);
        setPayoutUpdating(false);
        setPayoutUpdated(true);

        ref();
    }

    const approve = async (driver: Driver) => {
        if (!confirmingApproval) {
            setConfirmingApproval(true);
            return;
        }

        setConfirmingApproval(false);

        if (driver.status === DriverStatus.Active || driver.status === DriverStatus.Blocked) {
            setConfirmingApproval(false);
            return;
        }

        let status = driver.status === DriverStatus.New ? DriverStatus.PendingData : DriverStatus.PendingData ? DriverStatus.PendingApproval : DriverStatus.PendingApproval ? DriverStatus.Active : null;
        if (driver.status === DriverStatus.PendingApproval) {
            status = DriverStatus.Active;
        }
        try {
            await Api.patch(Endpoint.DRIVER, { id: driver.id, status });
            if (status === DriverStatus.Active) {
                setApproved(true);
            }
            if (onApprove) {
                onApprove();
            }
        } catch (err) {
            console.error(err);
        }
    }
    const onclickRecover = async (selectedDriver: Driver) => {
        const response = await Api.post<Driver, { email: string }>(Endpoint.DRIVER_RECOVER, { email: selectedDriver.email as string });
        if (onApprove && setSelectedDriver) {
            setSelectedDriver(undefined);
            onApprove();
        }
    }
    const renderApprovalMessage = (driver: Driver): ReactNode => {
        if (driver.deletedTime) {
            return (
                <div className="alert alert-success borderless">
                    <h5 className="alert-heading">Driver Recovery</h5>
                    <p>Do you want to recover this driver account?</p>
                    <div className="alert-btn">
                        <a className="btn btn-white-gold" href={'#href'} onClick={() => onclickRecover(selectedDriver)}>
                            <i className="os-icon os-icon-ui-92"></i>
                            <span>{'Recover Account'}</span>
                        </a>
                    </div>
                </div>
            );
        }
        if (approved) {
            return (
                <div className="alert alert-success borderless">
                    <h5 className="alert-heading">Driver Approved</h5>
                    <p>Driver has been successfully approved.</p>
                </div>
            );
        }

        switch (driver.status) {
            case DriverStatus.New:
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting initial approval</h5>
                        <p>This driver has just submitted their request to register as a drover. Once you approve them, they will be asked to fill in their full details.</p>
                        <div className="alert-btn">
                            <a className="btn btn-white-gold" onClick={() => approve(selectedDriver)}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>{confirmingApproval ? 'Confirm Approval' : 'Approve'}</span>
                            </a>
                        </div>
                    </div>
                );
            case DriverStatus.PendingData:
                if (driver.type === 'rental') {
                    return (
                        <div className="alert alert-warning borderless">
                            <h5 className="alert-heading">Taxi Rental Pending Signature</h5>
                            <p>This driver has been sent a rental agreement. Waiting on them to sign it.</p>
                        </div>
                    )
                }
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting details</h5>
                        <p>This driver has received an initial approval and needs to fill in their details. Once the details have been vetted, you need to give them the final approval.</p>
                    </div>
                );
            case DriverStatus.PendingApproval:
                if (driver.type === 'rental') {
                    return (
                        <div className="alert alert-warning borderless">
                            <h5 className="alert-heading">Taxi Rental Pending Agreement</h5>
                            <p>This driver has enrolled for taxi rental. Send them an agreement. Once they sign it, they will be active and ready to use the system.</p>
                            {rentalError && (
                                <div className="alert alert-danger borderless">
                                    <h5 className="alert-heading">Error</h5>
                                    <p>{rentalError}</p>
                                </div>
                            )}
                            {rentalSuccess && (
                                <div className="alert alert-success borderless">
                                    <h5 className="alert-heading">Success</h5>
                                    <p>{rentalSuccess}</p>
                                </div>
                            )}
                            <div className="form-group">
                                <input type={'number'} min="0" value={rentalAmount} className='form-control' placeholder='Weekly Rental Amount' onChange={e => setRentalAmount(e.target.value as any)} />
                            </div>
                            <div className="form-group">
                                <select value={selectedRentalVehicleId} onChange={(e) => setSelectedRentalVehicleId(e.target.value as string)} className="form-control">
                                    <option>Select Vehicle</option>
                                    {vehicles && vehicles.map((vehicle: Vehicle) => (
                                        <option key={vehicle.id} value={vehicle.id}>{vehicle.plate}</option>
                                    ))}
                                </select>
                                <div className="form-group" style={{ marginTop: 20 }}>
                                    <button className='btn btn-primary form-control'
                                        style={{ padding: '9px' }}
                                        disabled={!selectedRentalVehicleId || !rentalAmount || sendingRentalAgreement}
                                        onClick={onSendAgreement}>
                                        {sendingRentalAgreement ? 'Sending...' : 'Send Agreement'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="alert alert-warning borderless">
                        <h5 className="alert-heading">Driver awaiting final approval</h5>
                        <p>This driver has received an initial approval and has filled our their details. Once the details have been vetted, you need to give them the final approval.</p>
                        <div className="alert-btn">

                            {approveStatus == ApproveButtonStatus.APPROVE ? <a className="btn btn-white-gold" href={'#href'} onClick={() => {
                                setApproveStatus(ApproveButtonStatus.CONFIRM)
                                approve(selectedDriver)
                            }}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>Approve</span>
                            </a> : ""}

                            {approveStatus == ApproveButtonStatus.CONFIRM ? <button className="btn btn-white-gold" onClick={() => {
                                setApproveStatus(ApproveButtonStatus.APPROVING)
                                approve(selectedDriver)
                            }}>
                                <i className="os-icon os-icon-ui-92"></i>
                                <span>{confirmingApproval ? 'Confirm Approval' : 'Approving.....'}</span>
                            </button> : ""}


                            {approveStatus == ApproveButtonStatus.APPROVING ?
                                <button disabled className="btn btn-white-gold">
                                    <i className="os-icon os-icon-ui-92"></i>
                                    <span>Approving.....</span>
                                </button> : ""}



                        </div>
                    </div>
                );
        }

        return null;
    }
    const changeTime = (e: any) => {
        setTime(e.target.value)
    }
    const changePayment = (e: any) => {
        setPaymentType(e.target.value)
    }
    const refreshStats = useCallback(async (customStart?: Date, customEnd?: Date) => {
        let startDate: Date, endDate: Date, timeline = time;
        if (customStart && customEnd) {
            startDate = customStart; endDate = customEnd; timeline = StatsFilterPeriod.Custom;
        }
        else {
            ({ startDate, endDate } = getDatesForFilters(time)); timeline = time;
        }
        const response = await Api.get<StatsResponse, StatsRequest>(Endpoint.STATS, {
            startDate, endDate, timeline, driver: driver.id
        });
        setStats(response);
    }, [time]);

    useEffect(() => {
        const Payload: any | undefined = AuthService.getUser();
        const { user } = Payload;
        const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
        const moduleCodes = Array.from(assignments, assignment => ({
            module: assignment.module,
            action: assignment.action
        }));
        const newCodes = moduleCodes.filter((item: any) => {
            return item.module == "driver" && item.action == "deposit"
        })
        // if (newCodes?.length > 0) {
        //     setDepositAccess(true)
        // }
        // else {
        //     setDepositAccess(false)
        // }

        const balance = moduleCodes.filter((item: any) => {
            return item.module == "driver" && item.action == "view-balance"
        })
        // if (balance?.length > 0) {
        //     setBalanceAccess(true)
        // }
        // else {
        //     setBalanceAccess(false)
        // }
        const settings: Settings | undefined = SettingsService.getSetting();
        const currency = settings?.generalCurrency ? (settings?.generalCurrency as Currency).symbol : null;
        const unit = settings?.generalCurrency ? (settings?.generalCurrency as Currency).subunits : 100;
        setSymbol(currency)
        setSubUnit(unit)
    }, []);
    useEffect(() => {
        setApproved(false);
        setConfirmingApproval(false);
    }, [selectedDriver]);
    useEffect(() => {
        refreshStats();
    }, [refreshStats]);
    useEffect(() => {
        init();
        ref();
    }, [selectedDriver, init]);
    useEffect(() => {
        refreshTransactions()
    }, [selectedDriver, paymentFilterPaidStatus, paymentFilterPeriod, selectedTransactionType]);
    useEffect(() => {
        setTransactions(transactions.map((transaction: Transaction) => {
            transaction.selected = paySelectAll;
            return transaction;
        }));
    }, [paySelectAll]);

    function onCustomSelect(startDate: Date, endDate: Date) {
        refreshStats(startDate, endDate);
    }

    return (
        <ContentWrapper className="row">
            <ViewContent
                componentName='driver'
                className="col-md-6"
                title={getFullName(selectedDriver)}
                subtitle={selectedDriver.code}
                imgSrc={getProfilePic(selectedDriver, ProfilePicSize.Single)}
                selectedId={selectedDriver.id!}
            >
                <ViewContentItems>
                    {driver.stripeStatus == StripeStatus.Active ?
                        renderApprovalMessage(selectedDriver) :
                        driver.stripeStatus == StripeStatus.Overdue ?
                            <div className="alert alert-danger borderless">
                                {approveMessage?.length > 0 ?
                                    <ul>
                                        {approveMessage.map((item: any, index: any) => <li key={index}>{item.reason}</li>)}
                                    </ul>
                                    : ""}
                            </div> :
                            <div className="alert alert-danger borderless">
                                <h5 className="alert-heading">{t('driver.approve.verification')}</h5>
                                {approveMessage?.length > 0 ?
                                    <>
                                        <h5 style={{ textAlign: "center", marginTop: "25px" }} className="alert-heading">{t('Application Rejected')}</h5>
                                        <ul>
                                            {approveMessage.map((item: any, index: any) => <li key={index}>{item.reason}</li>)}
                                        </ul>
                                    </>
                                    : ""}
                            </div>
                    }
                    <ViewContentItem title={t('common.email')}>
                        <a href={`mailto:${driver?.email}`}>{driver?.email}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t('common.phone')}>
                        <a href={`tel:${driver?.phone}`}>{driver?.phone}</a>
                    </ViewContentItem>

                    <ViewContentItem title={t('common.gender')}>
                        {capitalize(driver?.gender)}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.dob')}>
                        {getLocaleDateOfBirth(driver)}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.homeAddress')}>
                        {driver?.address?.text}
                    </ViewContentItem>

                    {process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL &&
                        <ViewContentItem title={`${process.env.REACT_APP_BUSSINESS_REGISTRATION_LABEL}`}>
                            {driver?.businessRegistration}
                        </ViewContentItem>
                    }

                    <ViewContentItem title={t('common.fleet')}>
                        {driver?.fleets?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.networks')}>
                        {driver?.networks?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.operators')}>
                        {driver?.operators?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.groups')}>
                        {driver?.groupDrivers?.map((n: any) => n.group.title).join(', ')}
                    </ViewContentItem>

                    <ViewContentItem title={t('common.consent')}>
                        {driver?.consent ? <div style={{ color: "#047bf8" }} onClick={onOpenModal}>Version {driver?.consent.version} ({getLocaleDateOfConsent(driver)})</div> : null}
                        <Modal open={open} onClose={onCloseModal} center>
                            <h2 style={{ marginTop: '20px' }}>Driver Agreement</h2>
                            <p>{driver?.consent?.driverAgreement}</p>
                            <h2 style={{ marginTop: '5px' }}>Driver Use Of Information</h2>
                            <p>{driver?.consent?.driverUseOfInformation}</p>
                        </Modal>
                    </ViewContentItem>
                    <ViewContentItem title={t('common.features')}>
                        {driver?.features?.map((n: any) => n.title).join(', ')}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyName')}>
                        {driver?.emergencyName}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyPhone')}>
                        {driver?.emergencyPhone}
                    </ViewContentItem>
                    <ViewContentItem title={t('driver.emergencySection.emergencyEmail')}>
                        {driver?.emergencyEmail}
                    </ViewContentItem>
                </ViewContentItems>
                { driver.files && driver.files.length > 0 ?
                    <ViewContent title='Documents'>
                        <ViewContentFiles
                            key={selectedDriver.id}
                            files={driver.files.map((file: any) => getFileSrc(file.file) || [])}
                        />
                    </ViewContent>
                    :
                    <>
                        <h4>Documents</h4>
                        <p>No documents uploaded</p>
                    </>
                }
            </ViewContent>
            <div className="todo-list element-box col-md-6">

                <div className="row">
                    {error && <div className="alert alert-danger btn-block" style={{ background: '#ffbebea6' }} role="alert">
                        <h6>{error}</h6>
                    </div>}
                    {successMessage && <div className="alert alert-success btn-block" style={{ background: '#b0e7b0' }} role="alert">
                        <h6>{successMessage}</h6>
                    </div>}
                    {vehicleError && <div className="alert alert-danger btn-block" style={{ background: '#ffbebea6' }} role="alert">
                        <h6>{vehicleError}</h6>
                    </div>}

                    {deviceAuthCode && <div className="alert alert-success btn-block" style={{ background: '#b6ddb1b5' }} role="alert">
                        <h6>Auth Code: {deviceAuthCode}</h6>
                    </div>}
                </div>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <input className='form-control' onKeyDown={_handleKeyDown} onChange={(e) => setDeviceCode(e.target.value)} placeholder={t('common.deviceCode')} />
                    </div>
                    <div className="col-md-4 form-group">
                        <input className='form-control' onKeyDown={_handleKeyDown} onChange={(e) => setVehicleCode(e.target.value)} placeholder={t('common.vehiclePlate')} />
                    </div>
                    <div className="col-md-4">
                        <button className='btn btn-primary' style={{ padding: '9px' }} onClick={onGetDeviceCode}>{t('common.getAuthCode')}</button>
                        {/* <button className='btn btn-primary' style={{ padding: '9px', marginTop:"5px", marginBottom:"5px" }} onClick={payexternal}>{t('common.verifyStripe')}</button> */}
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-4 form-group">
                        <input type='number' min="0" value={payAmount} className='form-control' onChange={(e) => { setPayAmount(parseFloat(e.currentTarget.value)) }} placeholder={'Pending Amount'} />
                    </div>
                    <div className="col-md-4 form-group">
                        <select value={payProvider} onChange={(e) => { setPayProvider(e.currentTarget.value) }} className="form-control">
                            {/* <option value="cash">Cash</option> */}
                            <option value="bank">Bank</option>
                            <option value="soldo">Soldo</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <button className='btn btn-primary' style={{ padding: '9px 14.5px' }} onClick={ onConfirmPayout }>Pay Driver</button>
                    </div>
                </div>

                { pendingPayoutRequest && (
                    <div className='alert alert-warning'>
                        <h5 className='alert-heading'>Pending Payout</h5>
                        <p>There is a pending payout request for this driver.</p>
                        <p><strong>Requested Method:</strong> {pendingPayoutRequest.provider.toUpperCase()}</p>
                        <div className='row'>
                            <div className='form-group col-md-6'>
                                <label>Assigned Time (optional):</label>
                                <input className='form-control' type='datetime-local' value={ payoutAssignedTime } onChange={(e) => { setPayoutAssignedTime(e.currentTarget.value) }} />
                            </div>
                            <div className='form-group col-md-6'>
                                <label>Message (optional):</label>
                                <textarea className='form-control' value={ payoutMessage } onChange={(e) => { setPayoutMessage(e.currentTarget.value) }} rows={3}></textarea>
                            </div>
                        </div>
                        <div className='alert-btn'>
                            <button className='btn btn-primary' onClick={() => { onUpdatePayout(PayoutRequestStatus.Approved) }}>Approve</button>&nbsp;
                            <button className='btn btn-danger' onClick={() => { onUpdatePayout(PayoutRequestStatus.Rejected) }}>Reject</button>&nbsp;
                        </div>
                    </div>
                )}

                { payoutUpdating && (
                    <div className='alert alert-warning'>
                        <h5 className='alert-heading'>Payout Request</h5>
                        <p>Updating payout request...</p>
                    </div>
                )}

                { payoutUpdated && (
                    <div className='alert alert-success'>
                        <h5 className='alert-heading'>Payout Updated</h5>
                        <p>Payout request updated successfully. Driver has been informed.</p>
                    </div>
                )}

                { payoutConfirming && (
                    <div className='alert alert-warning'>
                        <h5 className='alert-heading'>Confirm Payout</h5>
                        <p>Are you sure you want to pay the driver {getCurrencyFormatting(payAmount * subUnit)} via {payProvider}?</p>
                        <p><input className='form-control' type='text' placeholder='Payment Reference' value={payProviderRef} onChange={e => setPayProviderRef(e.currentTarget.value)} /></p>
                        <div className='alert-btn'>
                            <button className='btn btn-primary' onClick={onSendPayout}>Confirm</button>&nbsp;
                            <button className='btn btn-white-gold' onClick={onCancelPayout}>Cancel</button>
                        </div>
                    </div>
                )}

                { payoutProcessing && (
                    <div className='alert alert-warning'>
                        <h5 className='alert-heading'>Processing Payout</h5>
                        <p>Processing payout of {getCurrencyFormatting(payAmount * subUnit)}...</p>
                    </div>
                )}

                { payoutProcessed && (
                    <div className='alert alert-success'>
                        <h5 className='alert-heading'>Payout Processed</h5>
                        <p>Payout of {getCurrencyFormatting(payAmount * subUnit)} processed successfully.</p>
                    </div>
                )}

                { payoutError && (
                    <div className='alert alert-danger'>
                        <h5 className='alert-heading'>Payout Failed</h5>
                        <p>{payoutError}</p>
                    </div>
                )}

                <div className='row'>
                    <ul className="nav nav-tabs" style={{ flexWrap: "wrap" }}>
                        <li key="payments" className="nav-item">
                            <button
                                className={classNames('nav-link', { active: selectedTransactionType === 'payments' })}
                                data-toggle="tab"
                                onClick={() => {
                                    setSelectedTransactionType('payments');
                                    return false
                                }}
                            >
                                Payments
                            </button>
                        </li>
                        <li key="payouts" className="nav-item">
                            <button
                                className={classNames('nav-link', { active: selectedTransactionType === 'payouts' })}
                                data-toggle="tab"
                                onClick={() => {
                                    setSelectedTransactionType('payouts');
                                    return false
                                }}
                            >
                                Payouts
                            </button>
                        </li>
                    </ul>
                    {selectedTransactionType === 'payments' && (
                        <div className='row' style={{ maxHeight: 'calc(100vh - 356px)', overflowY: 'auto' }}>
                            <div className='d-flex' style={{ width: '96%', marginTop: 10, gap: 10 }}>
                                <select value={paymentFilterPaidStatus} onChange={(e) => setPaymentFilterPaidStatus(e.currentTarget.value as any)} className='col-md-4 form-control form-control-sm'>
                                    <option value="unpaid">Unpaid</option>
                                    <option value="paid">Paid</option>
                                    <option value="all">Paid &amp; Unpaid</option>
                                </select>
                                <select value={paymentFilterPeriod} onChange={(e) => setPaymentFilterPeriod(e.currentTarget.value as any)} className="col-md-8 form-control form-control-sm">
                                    <option value={StatsFilterPeriod.UntilYesterdayMidnight}>{t('dashboard:dropdown.untilYesterdayMidnight')}</option>
                                    <option value={StatsFilterPeriod.Today}>{t('dashboard:dropdown.today')}</option>
                                    <option value={StatsFilterPeriod.Yesterday}>{t('dashboard:dropdown.yesterday')}</option>
                                    <option value={StatsFilterPeriod.ThisWeek}>{t('dashboard:dropdown.thisWeek')}</option>
                                    <option value={StatsFilterPeriod.LastWeek}>{t('dashboard:dropdown.lastWeek')}</option>
                                    <option value={StatsFilterPeriod.ThisMonth}>{t('dashboard:dropdown.thisMonth')}</option>
                                    <option value={StatsFilterPeriod.LastMonth}>{t('dashboard:dropdown.lastMonth')}</option>
                                    <option value={StatsFilterPeriod.ThisYear}>{t('dashboard:dropdown.thisYear')}</option>
                                    <option value={StatsFilterPeriod.Custom}>{t('dashboard:dropdown.custome')}</option>
                                </select>
                            </div>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        { paymentFilterPaidStatus === 'unpaid' && <th><input type="checkbox" checked={paySelectAll} onChange={() => setPaySelectAll(!paySelectAll)} /></th> }
                                        <th>Payment Code</th>
                                        <th>Amount</th>
                                        <th>Earning</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!transactionsLoading && transactions.map((transaction: Transaction) => (
                                        <tr key={transaction.id}>
                                            { paymentFilterPaidStatus === 'unpaid' && 
                                                <td><input type="checkbox" checked={transaction.selected} onChange={() => toggleTransactionSelect(transaction.id as string)} /></td>
                                            }
                                            <td>{transaction.code.toUpperCase()}</td>
                                            <td>{getCurrencyFormatting(transaction.amount)}</td>
                                            <td>{getCurrencyFormatting(transaction.earning)}</td>
                                            <td>{dateFormate(transaction.createTime)}</td>
                                        </tr>
                                    ))}
                                    { !transactionsLoading && transactions.length === 0 && <tr><td colSpan={5} style={{ textAlign: 'center' }}>No payments found</td></tr> }
                                    { transactionsLoading && <tr><td colSpan={5} style={{ textAlign: 'center' }}>Loading...</td></tr> }
                                </tbody>
                            </table>
                        </div>
                    )}
                    {selectedTransactionType === 'payouts' && (
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Payout Code</th>
                                    <th>Amount</th>
                                    <th>Via</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!transactionsLoading && transactions.map((transaction: any) => (
                                    <tr key={transaction.id}>
                                        <td>{transaction.code.toUpperCase()}</td>
                                        <td>{getCurrencyFormatting(transaction.amount)}</td>
                                        <td>{transaction.provider}</td>
                                        <td>{dateFormate(transaction.createTime)}</td>
                                    </tr>
                                ))}
                                { !transactionsLoading && transactions.length === 0 && <tr><td colSpan={5} style={{ textAlign: 'center' }}>No payouts found</td></tr> }
                                { transactionsLoading && <tr><td colSpan={5} style={{ textAlign: 'center' }}>Loading...</td></tr> }
                            </tbody>
                        </table>
                    )}
                </div>

                {depositAccess ? <div className="row">

                    <div className="col-md-4 form-group">
                        <input type={'number'} min="0" value={depositAmount} className='form-control' onKeyDown={_handleKeyDownDeposit} onChange={(e) => {
                            setSuccessMessage("");
                            setError(""); setDepositAmount(e.target.value as any)
                        }} placeholder={t('common.enterAmount')} />
                    </div>
                    <div className="col-md-4 form-group">
                        <select value={paymentType} onKeyDown={_handleKeyDownDeposit} onChange={changePayment} className="form-control">
                            <option value="cash">{translation.t('dropdown.cash')}</option>

                        </select>
                    </div>
                    <div className="col-md-4">
                        <button className='btn btn-primary' style={{ padding: '9px' }} onClick={onGetDeposit}>{t('driver.depositAmount')}</button>
                    </div>
                </div> : ""}

                {driver.status === DriverStatus.PendingApproval && !confirmSendInvite && !sendingInvite && (
                    <div className="row">
                        <div className="col-md-8 form-group">Invite driver to register on the platform</div>
                        <div className="col-md-4">
                            <button className='btn btn-primary' style={{ padding: '9px' }} onClick={() => { setConfirmSendInvite(true) }}>Send Invite SMS</button>
                        </div>
                    </div>
                )}

                {driver.status === DriverStatus.PendingApproval && confirmSendInvite && !sendingInvite && (
                    <div className="row">
                        <div className="col-md-4 form-group">Please confirm the invite</div>
                        <div className="col-md-4">
                            <button className='btn btn-primary' style={{ padding: '9px' }} onClick={onSendInviteSMS}>Confirm</button>
                        </div>
                        <div className="col-md-4">
                            <button className='btn btn-secondary' style={{ padding: '9px' }} onClick={() => { setConfirmSendInvite(false) }}>Cancel</button>
                        </div>
                    </div>
                )}

                {sendingInvite && (
                    <div className="row alert alert-warning btn-block form-group">
                        <b>Sending invite...</b>
                    </div>
                )}

                {sentInvite && (
                    <div className="row alert alert-success btn-block form-group">
                        <b>Invite sent successfully!</b>
                    </div>
                )}

                {balanceAccess ? <div className="row">

                    <div style={{ marginTop: "2rem" }} className="col-md-12 form-group">
                        <div className="alert alert-success btn-block" style={{ background: '#b6ddb1b5' }} role="alert">
                            <h6>Driver Balance: {symbol} {toSubUnit(driver.balance as number)}</h6>
                        </div>
                    </div>

                </div> : ""}

                {/* <StatsComponent
                    currentRange={time} onRangeChange={(e) => setTime(e.target.value)} onCustomSelect={onCustomSelect} data={!stats ? [] : [
                        { label: t('driver.stats.distance'), value: getGeneralDistanceFormat(stats.distance), isPositive: stats.distance >= stats.prevDistance, percentage: stats.distancePercentage },
                        { label: t('driver.stats.earnings'), value: getCurrencyFormatting(stats.revenue), isPositive: stats.revenue >= stats.prevRevenue, percentage: stats.revenuePercentage },
                        { label: t('driver.stats.rides'), value: stats.tripCount, isPositive: stats.tripCount >= stats.prevTripCount, percentage: stats.tripCountPercentage },
                        { label: t('driver.stats.shiftTime'), value: getGeneralTimeFormat(stats.rideTime), isPositive: stats.rideTime >= stats.prevRideTime, percentage: stats.tripCountPercentage }
                    ]}
                /> */}
                {inspectionAssignment.map((inspectionAssignment) => (
                    <Checklist
                        key={inspectionAssignment.inspection?.id}
                        value={inspectionAssignment}
                        onChange={newVal}
                    />
                ))}
            </div>

        </ContentWrapper>
    );
}